import { render, staticRenderFns } from "./ManagementAndSystem.vue?vue&type=template&id=37efc1a7&scoped=true&"
import script from "./ManagementAndSystem.vue?vue&type=script&lang=js&"
export * from "./ManagementAndSystem.vue?vue&type=script&lang=js&"
import style0 from "./ManagementAndSystem.vue?vue&type=style&index=0&id=37efc1a7&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37efc1a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VMenu})
