<template>
    <!--    <validation-observer ref="observer" v-slot="{ invalid }">-->
    <validation-observer ref="observer">
        <header class="event-view-header flex items-center justify-between px-8 pt-4">
            <div class="inline-flex items-center">
                <div class="text-xl mr-4">Task</div>
                <project-badge v-if="task && task.project" :project="task.project" icon-only large />
                <h2 class="card-title mb-0">
                    {{ isNewTask ? 'Create task' : initialData ? initialData.name : '' }}
                </h2>
                <div
                    v-if="initialData && initialData.project"
                    class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5 p-1 pt-2 mb-1"
                    :class="{'cursor-pointer': !isNewTask && task.type}"
                    @click="handleTitleLinkClick"
                >
                    {{ initialData && !isNewTask ? '/ ' + initialData.project.name : '' }}
                    <p
                        v-if="
                            task.type
                            && task.type.id === 3
                            && task.subject
                            && task.subject.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | Bid"}}
                    </p>
                    <p
                        v-if="
                            task.type
                            && task.type.id === 4
                            && task.subject
                            && task.subject.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | Resource"}}
                    </p>
                    <p
                        v-if="
                            task.type
                            && task.type.id === 5
                            && task.subject
                            && task.subject.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | " + task.subject.name }}
                    </p>
                    <p
                        v-if="
                            task.type
                            && task.type.id === 6
                            && task.subject
                            && task.subject.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | Daily"}}
                    </p>
                    <p
                        v-if="
                            task.type
                            && task.type.id === 7
                            && task.subject
                            && task.subject.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | Broadcast"}}
                    </p>
                    <p
                        v-if="
                            task.type
                            && task.type.id === 8
                            && task.subject
                            && task.subject.shot
                            && task.subject.shot.name
                        "
                        style="display: contents;"
                        class="font-gotham-500 text-xs text-gray-600 ml-2 translate-y-0.5"
                    >
                        {{" | " + task.subject.shot.name }} / {{ task.subject.passType }} / v{{ task.subject.version }}
                    </p>
                </div>
            </div>
            <div class="buttons">
                <v-btn icon small :to="$route.fullPath" target="_blank">
                    <i class="las la-external-link-alt" />
                </v-btn>
                <span class="ml-4">|</span>
                <v-btn icon class="ml-2" @click="$emit('updateTaskVisibility', false)">
                    <i class="las la-times" />
                </v-btn>
            </div>
        </header>
        <v-form class="relative" @submit.prevent="onSave(task.id, false)">
            <div class="grid grid-cols-6 gap-x-12 gap-y-8 p-8">
                <div class="col-span-4" >
                    <div
                        v-if="!isNewTask && task && task.type && [4, 5, 6, 7, 8].indexOf(task.type.id) !== -1"
                    >
                        {{/* Resource type task */}}
                        <div v-if="task.type.id === 4" class="aspect-w-1">
                            <div class="absolute w-full h-full bg-gray-300 flex items-center justify-center">
                                <i class="las la-file-alt text-2xl" />
                            </div>
                        </div>

                        {{/* Shot type task */}}
                        <div v-if="task.type.id === 5 && task.subject" class="aspect-w-1 relative">
                            <img :src="shotThumbnailPath" class="w-full h-full object-cover object-center" alt="" />
                        </div>

                        {{/* Shot type task */}}
                        <div v-if="task.type.id === 6 && task.subject" class="relative">
                            <img :src="shotThumbnailPath" class="w-full h-full object-cover object-center" alt="" />
                        </div>

                        {{/* Submit type task */}}
                        <div v-if="task.type.id === 8" class="relative">
                            <base-video-player
                                v-if="task.subject && task.subject.passType && !!task.subject.passType.match(/(CG|Comp)/)"
                                :submit="task.subject"
                            />
                            <base-audio-player
                                v-if="task.subject && task.subject.passType === 'Audio'"
                                :submit="task.subject"
                                small
                            />
                            <div v-if="task.subject && task.subject.passType === 'Image'" class="aspect-w-1">
                                <img
                                    :src="submitImageThumbnailPath"
                                    class="w-full h-full object-contain object-cover"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="task.description && getModalViewType().id === 'view'" class="col-span-3 mt-4">
                        <span class="form-label text-gray-600">Description</span>
                        <div v-if="task.description" class="ckeditor-content" v-html="task.description"></div>
                    </div>
                    <div v-if="getModalViewType().id === 'edit'" class="col-span-2">
                        <label class="form-label">Description</label>
                        <ck-editor
                            v-if="$checkPermission('task.create') || $checkPermission('task.update')"
                            v-model="task.description"
                        />
                        <div v-else class="mt-4" v-html="task.description"></div>
                    </div>
                </div>

                <div
                    v-if="getModalViewType().id === 'edit'"
                    :class="isNewTask || task.type.id === 2 || task.type.id === 3 ? 'col-span-2' : 'col-span-2'"
                >
                    <div class="grid grid-cols-6 gap-y-6 gap-x-4">
                        <div class="col-span-6">
                            <label class="form-label">Name*</label>
                            <validation-provider v-slot="{ errors }" vid="name" name="Name" class="relative block">
                                <v-text-field
                                    v-model="task.name"
                                    required
                                    solo
                                    dense
                                    autofocus
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </div>

                        <div class="col-span-6">
                            <div
                                class="grid gap-4"
                                :class="
                                    task.type && task.type.id !== 1 && task.type.id !== 2 && task.type.id !== 8
                                        ? 'grid-cols-3'
                                        : 'grid-cols-2'
                                "
                            >
                                <div>
                                    <label class="form-label">Type*</label>
                                    <validation-provider v-slot="{ errors }" vid="entityId" name="Task type">
                                        <v-select
                                            v-model="task.type"
                                            solo
                                            dense
                                            return-object
                                            required
                                            :items="taskTypes"
                                            :item-text="(item) => item.label"
                                            :error-messages="errors"
                                            @input="changeType($event)"
                                        />
                                    </validation-provider>
                                </div>

                                <div v-if="task.type && task.type.id !== 1 && task.type.id !== 2">
                                    <label class="form-label">Project</label>
                                    <div class="relative">
                                        <validation-provider v-slot="{ errors }" name="Project">
                                            <v-select
                                                v-model="task.project"
                                                solo
                                                dense
                                                return-object
                                                :items="projects_list"
                                                :error-messages="errors"
                                                @change="changeProject('change')"
                                            >
                                                <template #selection="{ item }">
                                                    <project-badge :project="item" />
                                                </template>
                                                <template #item="{ item }">
                                                    <project-badge :project="item" />
                                                </template>
                                            </v-select>
                                            <transition name="fade">
                                                <div
                                                    v-if="projectLoading"
                                                    class="absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 light:bg-gray-300 rounded"
                                                >
                                                    <i class="animate-spin las la-circle-notch text-xl" />
                                                </div>
                                            </transition>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div v-if="task.type && task.type.id === 8">
                                    <label class="form-label">Group</label>
                                    <div class="relative">
                                        <validation-provider v-slot="{ errors }" name="Project">
                                            <v-select
                                                v-model="task.sequence"
                                                solo
                                                dense
                                                return-object
                                                :items="sequences_list"
                                                :error-messages="errors"
                                                :disabled="!task.project || !task.project.id"
                                                @change="changeSequence('change')"
                                            >
                                                <template #selection="{ item }">
                                                    <project-badge :project="item" />
                                                </template>
                                                <template #item="{ item }">
                                                    <project-badge :project="item" />
                                                </template>
                                            </v-select>
                                            <transition name="fade">
                                                <div
                                                    v-if="sequenceLoading"
                                                    class="absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 light:bg-gray-300 rounded"
                                                >
                                                    <i class="animate-spin las la-circle-notch text-xl" />
                                                </div>
                                            </transition>
                                        </validation-provider>
                                    </div>
                                </div>

                                <div v-if="task.type">
                                    <label class="form-label">{{ task.type.label }}</label>
                                    <div class="relative">
                                        <validation-provider v-slot="{ errors }" name="Entity">
                                            <v-select
                                                v-model="task.entity"
                                                solo
                                                dense
                                                clearable
                                                return-object
                                                :items="taskEntityArray"
                                                :error-messages="errors"
                                                :disabled="
                                                    (task.type.id !== 1 && task.type.id !== 2 && !task.project) ||
                                                    (task.type.id === 8 && !task.sequence)
                                                "
                                            >
                                                <template #selection="{ item }">
                                                    <project-badge v-if="task.type.id === 2" :project="item" />
                                                    <span v-else class="dark:text-white">
                                                        {{ getEntityItemName(item) }}
                                                    </span>
                                                </template>
                                                <template #item="{ item }">
                                                    <project-badge v-if="task.type.id === 2" :project="item" />
                                                    <span v-else class="dark:text-white">
                                                        {{ getEntityItemName(item) }}
                                                    </span>
                                                </template>
                                            </v-select>
                                            <transition name="fade">
                                                <div
                                                    v-if="isEntityLoading"
                                                    class="absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 light:bg-gray-300 rounded"
                                                >
                                                    <i class="animate-spin las la-circle-notch text-xl" />
                                                </div>
                                            </transition>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-3">
                            <validation-provider v-slot="{ errors }" vid="dateStart" name="dateStart">
                                <label class="form-label">
                                    Task start date{{ isDispoCreationInProgress ? '*' : '' }}
                                </label>
                                <date-picker
                                    mode="date"
                                    :required="isDispoCreationInProgress"
                                    :date="task.dateStart || new Date()"
                                    @updateDate="(e) => (task.dateStart = $toISO(e))"
                                />
                                <input-error :errors="errors" />
                            </validation-provider>
                        </div>

                        <div class="col-span-3">
                            <validation-provider v-slot="{ errors }" vid="dateEnd" name="dateEnd">
                                <label class="form-label">
                                    Task end date
                                </label>
                                <date-picker
                                    mode="date"
                                    :date="task.dateEnd"
                                    @updateDate="(e) => (task.dateEnd = $toISO(e))"
                                />
                                <input-error :errors="errors" />
                            </validation-provider>
                        </div>

                        <div class="col-span-6">
                            <label class="form-label">Assignee*</label>
                            <validation-provider v-slot="{ errors }" vid="assignee" name="Assignee">
                                <v-autocomplete
                                    v-if="users && me"
                                    v-model="task.assignee"
                                    name="assigned_to"
                                    :items="sortedUsers.projectTeamMembers.concat(sortedUsers.notProjectTeamMembers)"
                                    :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                    :error-messages="errors"
                                    :menu-props="{ closeOnContentClick: true }"
                                    class="user-badges"
                                    placeholder="Select a user"
                                    solo
                                    dense
                                    return-object
                                >
                                    <template #selection="datas">
                                        <user-badge :user="datas.item" full-name is-small @click="datas.select" />
                                    </template>
                                    <template #item="{ item }">
                                        <user-badge :user="item" full-name />
                                    </template>
                                </v-autocomplete>
                            </validation-provider>
                        </div>
                        <div v-if="me.roles.includes('ROLE_ADMIN')" class="col-span-6" >
                            <label class="form-label">Review by*</label>
                            <validation-provider
                                v-if="me.roles.includes('ROLE_ADMIN')"
                                v-slot="{ errors }"
                                vid="creator"
                                name="Creator"
                                class="relative block"
                            >
                                <v-select
                                    v-model="task.created_meta"
                                    class="validate"
                                    solo
                                    dense
                                    clearable
                                    return-object
                                    :items="reviewMembers"
                                    :item-text="(item) => item.fullName"
                                    :error-messages="errors"
                                    item-value="id"
                                >
                                    <template #selection="{ item }">
                                        <user-badge :user="item" full-name is-small />
                                    </template>
                                    <template #item="{ item }">
                                        <div :key="Math.random()" class="w-full">
                                            <user-badge :user="item" full-name />
                                            <div
                                                v-if="
                                                    sortedUsers.projectTeamMembers.length &&
                                                    sortedUsers.projectTeamMembers.length !== sortedUsers.all.length &&
                                                    item.id ===
                                                        sortedUsers.projectTeamMembers[
                                                            sortedUsers.projectTeamMembers.length - 1
                                                        ].id
                                                "
                                                class="border-b border-gray-150 mt-2"
                                            ></div>
                                        </div>
                                    </template>
                                </v-select>
                            </validation-provider>
                        </div>



                        <div v-if="isNewTask && sortedUsers.all.length" class="col-span-6">
                            <label class="form-label">Notify members:</label>
                            <v-autocomplete
                                v-model="task.usersToNotify"
                                :items="sortedUsers.all"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                label="Notify members"
                                multiple
                                solo
                                dense
                                chips
                                return-object
                                deletable-chips
                            >
                                <template #selection="data">
                                    <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="
                                            task.usersToNotify = task.usersToNotify.filter(
                                                (user) => user.id !== data.item.id
                                            )
                                        "
                                    >
                                        <user-badge :user="data.item" is-small full-name />
                                    </v-chip>
                                </template>
                                <template #item="{ item }">
                                    <div :key="Math.random()" class="w-full">
                                        <user-badge :user="item" full-name />
                                        <div
                                            v-if="
                                                sortedUsers.projectTeamMembers.length &&
                                                sortedUsers.projectTeamMembers.length !== sortedUsers.all.length &&
                                                item.id ===
                                                    sortedUsers.projectTeamMembers[
                                                        sortedUsers.projectTeamMembers.length - 1
                                                    ].id
                                            "
                                            class="border-b border-gray-150 mt-2"
                                        ></div>
                                    </div>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                </div>

                <div v-else class="col-span-2">
                    {{/* * */}}
                    {{/* * */}}
                    {{/* * */}}
                    {{/* VIEW ONLY mode, no inputs (the only input is the status changer select at the bottom to update task status) */}}
                    {{/* * */}}

                    <div class="h-full flex flex-col justify-between">
                        <div class="grid grid-cols-3 gap-x-5 gap-y-12">

                            <div v-if="task.assignee" class="col-span-1">
                                <span class="form-label text-gray-600">Assignee</span>
                                <user-badge :user="task.assignee" is-small short-name />
                            </div>

                            <div v-if="task.created_meta" class="col-span-1">
                                <span class="form-label text-gray-600">Review by</span>
                                <user-badge :user="task.created_meta" is-small short-name />
                            </div>

                            <div v-if="task.reviewer" class="col-span-1">
                                <span class="form-label text-gray-600">Created by</span>
                                <user-badge :user="task.reviewer" is-small short-name />
                            </div>

                            <div v-if="task.assignee" class="col-span-1">
                                <span class="form-label text-gray-600">Created at</span>
                                <div class="text-sm">
                                    {{ $moment(task.created_meta.date).format('YYYY. MMM. DD. HH:mm') }}
                                </div>
                            </div>



                            <div class="col-span-1">
                                <span class="form-label text-gray-600">Start date</span>
                                <div class="text-sm">
                                    {{ $moment(task.dateStart).format('YYYY. MMM. D.') }}
                                </div>
                            </div>

                            <div class="col-span-1">
                                <span class="form-label text-gray-600">End date</span>
                                <div class="text-sm">
                                    {{ task.dateEnd ? $moment(task.dateEnd).format('YYYY. MMM. D.') : '-' }}
                                </div>
                            </div>

                            {{/* if task is of bid type */}}
                            <div v-if="task.type && task.type.id === 3" class="col-span-2">
                                <div class="form-label text-gray-600">Bid name</div>
                                <div>{{ task.subject.name }}</div>
                            </div>

                            {{/* if task is of shot type */}}
                            <div v-if="task.type && task.type.id === 5" class="col-span-2">
                                <div class="form-label text-gray-600">Shot name</div>
                                <div>{{ task.subject.name }}</div>
                            </div>

                            <TaskLog :logs="logs" class="col-span-3" />
                        </div>
                    </div>
                </div>

                {{/* * */}}
                {{/* * */}}
                {{/* * */}}
                {{/* VIEW MODE BUTTONS */}}
                {{/* * */}}
            </div>
            <footer
                class="shadow-footer fixed bg-white dark:bg-dark-850 flex justify-between w-1/1 xl:w-3/4 bottom-0 right-0 px-8 py-4"
            >
                <div>
                    <v-btn @click="closeTask">Cancel</v-btn>
                    <v-btn
                        v-if="getModalViewType().id === 'view'"
                        @click="updateSendMessage(true, task.id, 9, task.assignee.id, task.project.id)"
                    >
                        <i class="las la-envelope mr-2" />
                        Send message
                    </v-btn>
                </div>
                <div class="flex items-center justify-end gap-4">
                    <div v-if="getModalViewType().id === 'view'" class="inline-flex items-center">
                        <i v-if="postInProgress" class="animate-spin las la-circle-notch mr-3" />

                        <v-select
                            v-if="$checkPermission('task.update')"
                            v-model="task.status"
                            :items="filteredTaskStatusOptions"
                            :item-text="(item) => item.label"
                            solo
                            dense
                            :disabled="postInProgress"
                            return-object
                            hide-details
                            @change="onSave(task.id, true)"
                        />
                    </div>
                    <div v-if="getModalViewType().id !== 'view' && isNewTask && $checkPermission('task.create')">
                        <v-btn :disabled="disableSave" color="primary" type="submit">
                            <i class="las la-save mr-2" />
                            Save
                        </v-btn>
                    </div>
                    <div
                        v-else-if="
                            (me.roles.includes('ROLE_ADMIN') ||
                                (task.created_meta && task.created_meta.id === me.id)) &&
                            $checkPermission('task.update')
                        "
                    >
                        <div v-if="!isEditModeActive">
                            <v-btn color="primary" @click="isEditModeActive = true">
                                <i class="las la-pen mr-2" />
                                Edit
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="secondary" @click="isEditModeActive = false"> Discard changes </v-btn>
                            <v-btn color="primary" type="submit">
                                <i class="las la-save mr-2" />
                                Save
                            </v-btn>
                        </div>
                    </div>
                </div>
            </footer>
        </v-form>
        <task-comment-modal :task="taskId" :open="saveWithComment" @callback="editTask">
        </task-comment-modal>
    </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseVideoPlayer from '~/components/UI/Base/BaseVideoPlayer';
import BaseAudioPlayer from '~/components/UI/Base/BaseAudioPlayer';
import TaskLog from "@/components/Tasks/TaskLog.vue";
import TaskCommentModal from "@/components/Tasks/TaskCommentModal.vue";

export default {
    components: {
        TaskCommentModal,
        TaskLog,
        BaseVideoPlayer,
        BaseAudioPlayer,
    },
    props: {
        taskData: {
            type: Object,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            isEditModeActive: false,
            taskStatusOptions: [],
            filteredTaskStatusOptions: [],
            projects: [],
            initialData: null,
            difference: null,
            isInternalAudioLoading: null,
            isDownloadInProgress: null,
            loaders: {
                projects: false,
            },
            postInProgress: false,
            saveWithComment: false,
            isEntityLoading: false,
            sequenceLoading: false,
            projectLoading: false,
            projects_list: [],
            sequences_list: [],
            taskEntityArray: [],
            sortedUsers: {
                projectTeamMembers: [],
                notProjectTeamMembers: [],
                all: [],
            },
            reviewMembers:[],
            task: {
                project: null,
                type: null,
                entity: null,
                assignedTo: [],
                logs: [],
            },
            logs: [],
            taskId: null,
            disableSave: false,
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
            users: 'config/getUsers',
            me: 'users/me/getMe',
            isNewTask: 'tasks/getShowNewTask',
            savedTaskName: 'tasks/getSavedTaskName',
            savedTaskSummary: 'tasks/getSavedTaskSummary',
            savedTaskDescription: 'tasks/getSavedTaskDescription',
        }),
        isDispoCreationInProgress() {
            return this.$route.fullPath === '/dispo/users/new';
        },
        shotThumbnailPath() {
            if (
                this.task.type.id === 6 &&
                this.task.subject &&
                this.task.subject.submit &&
                this.task.subject.submit.shot &&
                this.task.subject.submit.shot.thumbnailPath
            ) {
                return `${process.env.backendURL}/${this.task.subject.submit.shot.thumbnailPath}`;
            } else if (!this.task.subject || !this.task.subject.thumbnailPath) {
                return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
            }
            return `${process.env.backendURL}/${this.task.subject.thumbnailPath}`;
        },
        submitImageThumbnailPath() {
            return `${process.env.backendURL}/${this.task.subject.thumbnailPath}`;
        },
        taskTypes() {
            return this.$getTaskTypesFilteredByUserPermission();
        },
    },
    watch: {
        task: {
            handler(value) {
                this.sortedUsers = this.getUsersGroupedAndSortedBySelectedProject();
                this.reviewMembers = this.getReviewerUsers();
                if (value) {
                    this.difference = this.$difference(value, this.initialData || {});
                    this.$emit('watchTaskData', value);
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.$store.commit('tasks/setIsTaskDetailsMounted', true);

        this.task = {
            ...this.taskData,
            name: this.savedTaskName ? this.savedTaskName : this.taskData.name,
            summary: this.savedTaskSummary ? this.savedTaskSummary : this.taskData.summary,
            description: this.savedTaskDescription
                ? this.savedTaskDescription
                : this.taskData.description
                ? this.taskData.description
                : '',
        };

        if (!this.task.created_meta) {
            this.task.created_meta = this.me;
        }
        this.initialData = { ...this.task };

        this.taskStatusOptions = this.config.dispo.task_statuses;

        this.filteredTaskStatusOptions = this.$getPossibleTaskStatusesToUpdateTaskTo(this.task);

        this.loadProjects = this.$_.debounce(this.loadProjects);
        this.loadBids = this.$_.debounce(this.loadBids);
        this.loadResources = this.$_.debounce(this.loadResources);
        this.loadShots = this.$_.debounce(this.loadShots);
        this.loadSubmits = this.$_.debounce(this.loadSubmits);
        this.loadDailies = this.$_.debounce(this.loadDailies);
        this.loadBroadcasts = this.$_.debounce(this.loadBroadcasts);
        this.changeProject = this.$_.debounce(this.changeProject, 500);

        this.task.type = this.task.type.id ? this.taskTypes.find((data) => data.id === this.task.type.id) : null;

        if (this.task.type) {
            switch (this.task.type.id) {
                case 2:
                    this.loadProjects();
                    break;
                case 3:
                    this.loadProjects('category');
                    this.loadBids(this.task.project.id);
                    break;
                case 4:
                    this.loadProjects('category');
                    this.loadResources(this.task.project.id);
                    break;
                case 5:
                    this.loadProjects('category');
                    this.loadShots(this.task.project.id);
                    break;
                case 6:
                    this.loadProjects('category');
                    this.loadDailies(this.task.project.id);
                    break;
                case 7:
                    this.loadProjects('category');
                    this.loadBroadcasts(this.task.project.id);
                    break;
                case 8:
                    this.loadProjects('category');
                    if (this.task.id) {
                        this.loadSubmits(this.task.project.id);
                    } else {
                        this.loadSequences(this.task.project.id);
                    }
                    break;
            }
            this.loadLogs();
            // this.changeType({ id: this.task.type.id });
        }

        window.addEventListener('keyup', this.handleEscapeKeyPress);
    },
    destroyed() {
        window.removeEventListener('keyup', this.handleEscapeKeyPress);
        this.taskEntityArray = [];
        this.task.entity = null;
        this.$store.commit('tasks/setTaskEntityID', null);
        this.$store.commit('tasks/setTaskType', null);
        this.$store.commit('tasks/setIsTaskDetailsMounted', false);
    },
    methods: {
        updateSendMessage(state, entityId, type, recipient, projectID) {
            this.$store.dispatch('messages/setShowNewMessage', { state, entityId, type, recipient, projectID });
        },
        handleEscapeKeyPress(key) {
            if (key.code === 'Escape') {
                this.closeTask();
            }
        },
        changeType(value) {
            this.taskEntityArray = [];
            this.projects_list = [];
            this.sequences_list = [];
            this.task.entity = null;
            this.task.sequence = null;
            this.task.project = null;

            if (value) {
                if (value.id === 2) {
                    this.loadProjects();
                } else if (value.id === 3) {
                    this.loadProjects('category', 'change');
                } else if (value.id === 4) {
                    this.loadProjects('category', 'change');
                } else if (value.id === 5) {
                    this.loadProjects('category', 'change');
                } else if (value.id === 6) {
                    this.loadProjects('category', 'change');
                } else if (value.id === 7) {
                    this.loadProjects('category', 'change');
                } else if (value.id === 8) {
                    this.loadProjects('category', 'change');
                }
            }
        },
        async loadLogs() {
            await this.$axios
            .$get(`${process.env.prodAPI}/tasklog/${this.task.id}`)
            .then((res) => {
              this.logs = res.result;
            })
            .catch((err) => {
              this.$notify({ title: err.response.data.message, type: 'error' });
            });
        },
        async loadProjects(type, state) {
            let filterQuery = 'overLimit:eq:true';

            switch (this.task.type.id) {
                case 2:
                    filterQuery = '';
                    break;
                case 3:
                    filterQuery = '&scopes[]=with_bid_info';
                    break;
                case 4:
                    filterQuery = '&scopes[]=with_resources';
                    break;
                case 5:
                    filterQuery = '';
                    break;
                case 6:
                    filterQuery = '';
                    break;
                case 7:
                    filterQuery = '';
                    break;
                case 8:
                    filterQuery = '';
                    break;
            }

            this.projectLoading = true;

            if (this.task.type.id !== 8) {
                this.isEntityLoading = true;
            }

            await this.$axios
                .$get(`${process.env.prodAPI}/projects?order=name&scopes[]=with_subscribed_users&${filterQuery}&filters[]=overLimit:eq:true`)
                .then((res) => {
                    if (type === 'category') {
                        this.projects_list = this.$clone(res.result);

                        if (this.task.project) {
                            this.task.project = this.projects_list.find(
                                (data) => data.id === parseInt(this.task.project.id)
                            );
                        }
                        if (state === 'change') {
                            this.changeProject();
                        }
                        this.projectLoading = false;
                    } else {
                        this.taskEntityArray = this.$clone(res.result);
                        if (this.task.entity && this.task.entity.id) {
                            this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                        }
                    }

                    if (this.$refs.observer) {
                        this.$refs.observer.reset();
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadBids(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }

            this.isEntityLoading = true;

            await this.$axios
                .$get(`${process.env.prodAPI}/bids?order=name`, { params })
                .then((res) => {
                    this.taskEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadResources(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }

            this.isEntityLoading = true;

            await this.$axios
                .$get(`${process.env.prodAPI}/resources?order=name`, { params })
                .then((res) => {
                    this.taskEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadShots(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `sequence.project:eq:${projectID}`);
            }

            this.isEntityLoading = true;

            await this.$axios
                .$get(`${process.env.prodAPI}/shots?order=name`, { params })
                .then((res) => {
                    this.taskEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadSequences(projectID) {

            this.sequenceLoading = true;
            const params = new URLSearchParams();

            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }

            await this.$axios
                .$get(`${process.env.prodAPI}/sequences?limit=1000`, { params })
                .then((res) => {
                    this.sequences_list = res.result.filter((x) => x.shots.length);

                    if (this.task.sequenceId) {
                        this.task.sequence = this.sequences_list.find((x) => x.id === this.task.sequenceId);
                    }

                    this.loadSubmits(projectID);

                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.sequenceLoading = false;
                });
        },
        async loadSubmits(projectID) {
            const params = new URLSearchParams();

            if (projectID) {
                params.append('filters[]', `sequence.project:eq:${projectID}`);
            }

            this.isEntityLoading = true;
            if (this.task.id && !this.task.sequence) {
                await this.loadSequences(this.task.project.id);
            }

            await this.$axios
                .$get(`${process.env.prodAPI}/submits?limit=1000`, { params })
                .then((res) => {
                    if (this.task.id && !this.task.sequence) {
                        const submit = res.result.find((x) => this.task.entity.id === x.id);
                        this.task.sequence = this.sequences_list.find((x) =>
                            x.shots.find((y) => y.id === submit.shot.id)
                        );
                    }

                    this.taskEntityArray = res.result.reduce((acc, curr) => {
                        const shotIdsInSelectedSequence = this.task.sequence.shots.map((x) => x.id);
                        if (shotIdsInSelectedSequence.includes(curr.shot.id)) {
                            acc.push({
                                id: curr.id,
                                pass: curr.pass,
                                version: curr.version,
                            });
                        }

                        return acc;
                    }, []);

                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadDailies(projectID) {
            const params = new URLSearchParams();

            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
                params.append('scopes[]', 'with_pass_info');
            }

            this.isEntityLoading = true;

            await this.$axios
                .$get(`${process.env.prodAPI}/dailies`, { params })
                .then((res) => {
                    this.taskEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: this.getGeneratedDailyID(value),
                    }));
                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        async loadBroadcasts(projectID) {
            const params = new URLSearchParams();

            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }

            this.isEntityLoading = true;

            await this.$axios
                .$get(`${process.env.prodAPI}/broadcasts`, { params })
                .then((res) => {
                    this.taskEntityArray = Object.entries(res.result).map(([_, value]) => {
                        return {
                            id: value.id,
                            name: value.preset ? value.preset.name : '[no preset]',
                        };
                    });
                    if (this.task.entity && this.task.entity.id) {
                        this.task.entity = this.taskEntityArray.find((data) => data.id === this.task.entity.id);
                    }
                })
                .catch((err) => {
                    this.$notify({ title: err.response.data.message, type: 'error' });
                })
                .finally(() => {
                    this.isEntityLoading = false;
                });
        },
        changeProject() {

            if (!this.task.project) {
                return;
            }

            if (this.task.type.id === 3) {
                this.loadBids(this.task.project.id);
            } else if (this.task.type.id === 4) {
                this.loadResources(this.task.project.id);
            } else if (this.task.type.id === 5) {
                this.loadShots(this.task.project.id);
            } else if (this.task.type.id === 6) {
                this.loadDailies(this.task.project.id);
            } else if (this.task.type.id === 7) {
                this.loadBroadcasts(this.task.project.id);
            } else if (this.task.type.id === 8) {
                this.loadSequences(this.task.project.id);
            }

            this.sortedUsers = this.getUsersGroupedAndSortedBySelectedProject();
            this.reviewMembers = this.getReviewerUsers();
        },
        changeSequence() {
            this.loadSubmits(this.task.project.id);
        },
        closeTask() {
            if (this.isEditModeActive === false) {
                this.task.name = '';
                this.task.dateStart = '';
                this.task.dateEnd = '';
                this.task.description = '';
                this.task.descriptionsummary = '';

                this.$store.commit('tasks/setTaskProjectId', null);
                this.$store.commit('tasks/resetTask');
                this.$store.commit('tasks/setSavedTaskName', '');
                this.$store.commit('tasks/setSavedTaskSummary', '');
                this.$store.commit('tasks/setSavedTaskDescription', '');
                this.$emit('updateTaskVisibility', false);
            }
            this.isEditModeActive = false;
        },
        onSave(taskId, commentEnable = false) {

            if (this.isNewTask) {
                this.addTask();
            } else if(commentEnable) {
                this.taskId = taskId;
                this.saveWithComment = true;
            } else {
                this.editTask(taskId);
            }
        },
        generatePostData() {
            const postData = new FormData();

            if (this.getModalViewType().id === 'view' && this.getModalViewType().target !== 'admin') {
                if (this.task.status && this.task.status.id) {
                    postData.append('status', this.task.status.id);
                }
                return postData;
            }

            if (this.task.name) {
                postData.append('name', this.task.name);
            }
            if (this.task.status && typeof this.task.status.id === 'number') {
                postData.append('status', this.task.status.id);
            } else {
                postData.append('status', '1');
            }
            if (this.task.type) {
                postData.append('type', this.task.type.id);
            }
            if (this.task.entity) {
                postData.append('entityId', this.task.entity.id);
            }
            if (this.task.dateStart) {
                postData.append('dateStart', this.task.dateStart);
            }
            if (this.task.dateEnd) {
                postData.append('dateEnd', this.task.dateEnd);
            }
            if (this.task.description) {
                postData.append('description', this.task.description);
            } else {
                postData.append('description', '');
            }
            if (this.task.summary) {
                postData.append('summary', this.task.summary);
            } else {
                postData.append('summary', '');
            }
            if (this.task.assignee) {
                postData.append('assignee', String(this.task.assignee.id));
            } else {
                postData.append('assignee', '');
            }
            if (this.task.created_meta) {
                postData.append('created_by', String(this.task.created_meta.id));
            } else {
                postData.append('created_by', '');
            }
            if (this.task.usersToNotify && this.task.usersToNotify.length > 0) {
                for (const user of this.task.usersToNotify) {
                    postData.append('usersToNotify[]', user.id);
                }
            } else {
                postData.append('usersToNotify[]', '');
            }
            return postData;
        },
        addTask() {
            this.postInProgress = true;
            this.$axios
                .$post(`${process.env.prodAPI}/tasks`, this.generatePostData())
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.$nuxt.$emit('updateDispoAssignTaskModalWithJustCreatedTaskData', res.result);
                    this.closeTask();
                    this.$store.commit('tasks/setRefreshTasksTable', true);
                })
                .catch((err) => {
                    this.$refs.observer.setErrors(this.$formatErrorMessages(err.response.data.errors));
                })
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        editTask(taskId) {
            this.postInProgress = true;
            this.$axios
                .$post(`${process.env.prodAPI}/tasks/${taskId}`, this.generatePostData())
                .then((res) => {
                    this.postInProgress = false;
                    this.$notify({ title: res.message, type: 'info' });
                    this.initialData = { ...this.task };
                    this.difference = null;
                    this.$store.commit('tasks/setSavedTaskName', '');
                    this.$store.commit('tasks/setSavedTaskSummary', '');
                    this.$store.commit('tasks/setSavedTaskDescription', '');
                    this.$store.commit('tasks/setRefreshTasksTable', true);
                    this.closeTask();
                })
                .catch((err) => {
                    this.postInProgress = false;
                    for (const error of err.response.data.errors) {
                        this.$notify({
                            title: err.response.data.message,
                            text: `${error.key}: ${error.message}`,
                            type: 'error',
                        });
                    }
                });
            // this.isEditModeActive = false;
        },
        getGeneratedDailyID(daily) {
            const clientVersion = daily.clientVersion ? ' ' + daily.clientVersion : '';
            const preset = daily.preset ? ' (' + daily.preset + ')' : '';
            return `${daily.submit.pass.name} ${clientVersion} ${preset} #${daily.id}`;
        },
        getUsersGroupedAndSortedBySelectedProject() {
            let _project;

            if (this.task.project && this.task.project.id) {
                _project = this.task.project;
            } else if (this.task.entity && this.task.entity.id) {
                _project = this.taskEntityArray.find((x) => x.id === this.task.entity.id);
            }

            if (_project && _project.subscribedUsers) {
                const projectTeamMemberIds = _project.subscribedUsers.map((x) => x.user.id);

                const projectTeamMembers = this.users.filter((user) =>
                    projectTeamMemberIds.find((id) => user.id === id)
                );
                const notProjectTeamMembers = this.users.filter(
                    (user) => !projectTeamMemberIds.find((id) => user.id === id)
                );

                if (projectTeamMembers.length) {
                    projectTeamMembers[projectTeamMembers.length - 1].drawSeparatorLine = true;
                }

                return {
                    projectTeamMembers,
                    notProjectTeamMembers,
                    all: [...projectTeamMembers, ...notProjectTeamMembers],
                };
            }

            return {
                projectTeamMembers: [],
                notProjectTeamMembers: [],
                all: this.users,
            };
        },
        getReviewerUsers() {
            return this.users.filter((user) => {
                if (!user.roles.includes('ROLE_ARTIST')) {
                   return true;
                } 
                return false;
            });
        },
        getModalViewType() {
            if (this.isNewTask) {
                return {
                    id: 'edit',
                };
            }

            if (!this.task || !this.task.created_meta) {
                return {
                    id: 'view',
                };
            }

            const isTaskCreatedByUser = this.$user.id === this.task.created_meta.id;

            if (
                isTaskCreatedByUser ||
                this.$user.roles.includes('ROLE_ADMIN') ||
                this.$user.roles.includes('ROLE_SUPERVISOR') ||
                this.$user.roles.includes('ROLE_SUB_SUPERVISOR') ||
                this.$user.roles.includes('ROLE_PRODUCER') ||
                this.$user.roles.includes('ROLE_SUB_PRODUCER')
            ) {
                if (this.isEditModeActive) {
                    return {
                        id: 'edit',
                    };
                } else {
                    return {
                        id: 'view',
                        target: 'admin',
                    };
                }
            }
            return {
                id: 'view',
            };
        },
        handleDownloadDocumentClick(fileType, bidID) {
            this.isDownloadInProgress = false;
            this.$downloadFile(`${process.env.prodAPI}/bids/${bidID}/download-${fileType}`).finally(() => {
                this.isDownloadInProgress = false;
            });
        },
        goToUrlAndCloseModal(url) {
            this.$store.commit('tasks/setIsTaskModalOpen', { state: false, id: null });
            this.$router.push(url);
            document.querySelector("html").style = {};
        },
        getEntityItemName(item) {
            return item.fullName || item.name || `${item.pass.type} / ${item.pass.name} / v${item.version}`;
        },
        handleTitleLinkClick() {
            if (this.task && this.task.type && this.task.type.id) {
                switch (this.task.type.id) {
                    case 2:
                        if (this.task.project && this.task.project.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}`);
                        }
                        break;
                    case 3:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('broadcast.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/details/bids`);
                            } else if (!this.$user.roles.includes('ROLE_ARTIST')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/details`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}`);
                            }
                        }
                        break;
                    case 4:
                        if (this.task.project && this.task.project.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/resources`);
                        }
                        break;
                    case 5:
                        if (this.task.project && this.task.project.id && this.task.subject && this.task.subject.id) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/shots/${ this.task.subject.id }`);
                        }
                        break;
                    case 6:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('daily.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/dailies`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work`);
                            }
                        }
                        break;
                    case 7:
                        if (this.task.project && this.task.project.id) {
                            if (this.$checkPermission('broadcast.read_list')) {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/broadcast`);
                            } else {
                                this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work`);
                            }
                        }
                        break;
                    case 8:
                        if (this.task.project && this.task.project.id && this.task.subject && this.task.subject.id && this.task.subject.shot && this.task.subject.shot.id && this.task.subject.passType) {
                            this.goToUrlAndCloseModal(`/projects/${this.task.project.id}/work/shots/${this.task.subject.shot.id}/${this.task.subject.passType.toLowerCase()}/${this.task.subject.id}`);
                        }
                        break;
                    default:
                        break;
                }
            }
        },
    },
};
</script>
<style>
.ck-editor__editable {
    min-height: 200px;
}
</style>