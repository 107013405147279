<template>
    <v-menu
        ref="headerMessagesMenu"
        offset-y
        open-on-focus
        :close-delay="hangTime"
        persistent
    >
        <template #activator="{ on }">
            <v-btn
                icon
                medium
                class="ml-2"
                title="Messages"
                v-on="on"
                @mouseenter="openMenu"
                @mouseleave="closeMenu"
                @click="iconClick"
            >
                <i class="las la-envelope" />
                <span
                    v-if="newMessages"
                    class="absolute top-0 right-0 border-3 border-gray-300 dark:border-dark-800 bg-red text-white rounded-full w-4 h-4 flex items-center justify-center"
                />
            </v-btn>
        </template>

        <div
            v-click-outside="() => {
                if (!justOpened && (!currentView || !currentView[0]._data.dialog)) {
                    $refs.headerMessagesMenu._data.isActive = false;
                } else {
                    justOpened = false;
                }
            }"
            class="v-menu-body"
            style="padding: 0 0.75rem 0.75rem;"
            @mouseenter="openMenu"
            @mouseleave="closeMenu"
        >
            <div v-for="msg of headerMessages" :key="msg.id">
                <message-view-modal
                    :ref="`msgView-${msg.id}`"
                    :button-is-text="false"
                    :message="msg"
                    :type="`${msg.type}`"
                    button-classes="items-start"
                    use-anchor
                >
                    <div
                        class="flex flex-wrap items-center justify-start border-b border-gray-600 w-80 py-2 text-white"
                        @click="() => { preventClose(); currentView = $refs[`msgView-${msg.id}`]; }"
                    >
                        <div class="flex w-max text-xs">
                        <span
                            v-if="!isSeen(msg)"
                            class="bg-red text-white rounded-full w-4 h-4"
                            style="margin-top: 0.25rem; margin-right: 0.25rem;"
                        />
                            <user-badge is-small color="white" class="flex ml-auto mr-2" short-name :user="msg.created_meta" />
                        </div>
                        <div class="w-max text-base text-left my-3">
                            {{ formatPayload(msg.payload) }}
                        </div>
                        <div class="flex w-max text-xs">
                            <span v-if="msg.project">{{ msg.project.name }}</span>
                            <date :date="msg.created_meta.date" class="ml-auto" />
                        </div>
                    </div>
                </message-view-modal>
            </div>

            <div class="flex gap-4 mt-2">
                <v-btn color="tertiary"><NuxtLink to="/messages/inbox">Show all</NuxtLink></v-btn>
                <v-btn color="tertiary" class="ml-auto" @click="() => readAllMessages()">Mark all read</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageViewModal from '@/components/Messages/MessageViewModal.vue';

export default {
    components: { MessageViewModal },
    data() {
        return {
            justOpened: false,
            currentView: null,
            hangTime: 200,
            shouldClose: false,
            closePrevented: false,
        }
    },
    computed: {
        ...mapGetters({
            me: 'users/me/getMe',
            newMessages: 'messages/getUnreadMessages',
            headerMessages: 'messages/getHeaderMessages',
        }),
    },
    mounted() {
        this.$store.dispatch('messages/fetchHeaderMessages');
    },
    methods: {
        isSeen(msg) {
            let result = false;
            msg.recipients.forEach(recipient => {
                if (recipient.user.id === this.me.id) {
                    result = !!recipient.seen;
                }
            })
            return result;
        },
        formatPayload(payload) {
            const noHtml = payload.replaceAll(/(<([^>]+)>)/ig, ' ').replaceAll(/ +/ig, ' ');
            return noHtml.slice(0, Math.min(noHtml.length, 50)) + (noHtml.length > 50 ? '…' : '' );
        },
        readAllMessages() {
            const postData = new FormData();
            postData.append('setRead', true);
            postData.append('messages', 'all');
            this.$axios
                .$post(process.env.prodAPI + '/messages/inbox', postData)
                .then(() => {
                    this.$store.commit('messages/setRefreshMessagesTable', true);
                    this.$store.dispatch('messages/fetchHeaderMessages');
                })
                .catch((e) => {
                    for (const error of e.response.data.errors) {
                        this.$notify({
                            title: e.response.data.message,
                            text: `${error.key}: ${error.message}`,
                            type: 'error',
                        });
                    }
                });
        },
        iconClick() {
            setTimeout(() => {
                this.$refs.headerMessagesMenu._data.isActive = true;
            }, 50);
        },
        openMenu() {
            this.$refs.headerMessagesMenu._data.isActive = true;
            this.shouldClose = false;
        },
        closeMenu() {
            this.shouldClose = true;
            setTimeout(() => {
                if (this.closePrevented) {
                    this.closePrevented = false;
                    return;
                }
                if (this.shouldClose) {
                    this.$refs.headerMessagesMenu._data.isActive = false;
                }
            }, this.hangTime);
        },
        preventClose() {
            this.closePrevented = true;
        }
    }
};
</script>

<style></style>
