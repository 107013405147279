var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"-mb-8 mt-6"},[_c('span',{staticClass:"shadow-line shadow-line-before"}),_vm._v(" "),_c('nav',{staticClass:"sidebar-menu transition-all overflow-y-scroll overflow-x-hidden pb-2 pt-4 scrollbar-thin scrollbar-thumb-gray-500 dark:scrollbar-thumb-dark-800 scrollbar-track-gray-100 dark:scrollbar-track-dark-700",class:{ 'admin-open': _vm.adminMenuOpen }},[_c('transition-group',{staticClass:"filter_anim block",attrs:{"tag":"ul","name":"filter_anim"}},_vm._l((_vm.activeProjectSubscriptions),function(subscription){return _c('li',{key:'p_' + subscription.id,staticClass:"filter_anim-item last-of-type:pb-4"},[_c('button',{staticClass:"w-max",on:{"contextmenu":function($event){return _vm.showContextMenu($event, subscription)}}},[_c('div',{staticClass:"group flex items-center transition-colors hover:bg-gray-300 dark-hover:bg-dark-800"},[_c('nuxt-link',{staticClass:"sidebar-project-link py-3 px-4 w-100 transition-colors duration-300 flex items-center relative",attrs:{"to":_vm.projectLink(subscription.project.id, subscription.project.status)}},[_c('div',{staticClass:"color-badge w-1 h-full absolute top-0 left-0 transition-opacity bg-system-purple/50",class:_vm.$getProjectStatusColor(subscription.project.status).bg}),_vm._v(" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},_vm.sidebarOpen ? null : on),[_c('project-badge-inside',{attrs:{"project":subscription.project,"additional-classes":((!_vm.sidebarOpen ? 'ml-0.5' : 'pointer-events-none') + " "),"icon-only":"","has-large-icon":""},scopedSlots:_vm._u([{key:"insideBadge",fn:function(){return [(subscription.project.hasMilestoneToday)?_c('span',{staticClass:"dot -top-1.5 -left-1.5 bg-red"}):(subscription.project.hasMilestoneTomorrow)?_c('span',{staticClass:"dot -top-1.5 -left-1.5 bg-yellow"}):_vm._e()]},proxy:true}],null,true)})],1)]}}],null,true)},[_vm._v(" "),(!_vm.sidebarOpen)?_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(subscription.project.name))]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.sidebarOpen)?_c('span',{staticClass:"relative text-xs text-secondary dark:text-white font-gotham-400 max-w-[90px] overflow-hidden overflow-ellipsis"},[_vm._v("\n                                    "+_vm._s(subscription.project.name)+"\n                                ")]):_vm._e()])],1),_vm._v(" "),(
                                _vm.$checkPermission('project.update') ||
                                _vm.$checkPermission('message.create') ||
                                _vm.$checkPermission('task.create')
                            )?_c('v-menu',{attrs:{"close-on-content-click":true,"offset-y":"","transition":"slide-x-transition","z-index":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-auto mr-4 text-2xl",attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.showThreeButtonMenu[subscription.project.name] = true}}},on),[_c('i',{staticClass:"las la-ellipsis-v transition-colors text-gray-600 group-hover:text-secondary dark-group-hover:text-white",class:{
                                            'text-secondary dark:text-white': subscription.project.id === _vm.activePicker,
                                        }})])]}}],null,true),model:{value:(_vm.showThreeButtonMenu[subscription.project.name]),callback:function ($$v) {_vm.$set(_vm.showThreeButtonMenu, subscription.project.name, $$v)},expression:"showThreeButtonMenu[subscription.project.name]"}},[_vm._v(" "),_c('div',{staticClass:"v-menu-body"},[_c('ActionContextMenu',{attrs:{"custom":[
                                        {
                                            permission: _vm.$checkPermission('project.update'),
                                            label: 'Edit',
                                            url: ("/projects/" + (subscription.project.id) + "/details"),
                                            icon: 'la-cog',
                                        },
                                        {
                                            type: 'project-folder',
                                            label: 'Open folder',
                                            url: _vm.$folderPath(subscription.project.relPath),
                                            icon: 'la-folder-open',
                                        } ],"task":{
                                        entityId: subscription.project.id,
                                        entityTypeId: 2,
                                    },"message":{
                                        entityId: subscription.project.id,
                                        entityTypeId: 2,
                                    }}})],1)]):_vm._e()],1)])])}),0),_vm._v(" "),(
                _vm.$checkPermission('project.update') ||
                _vm.$checkPermission('message.create') ||
                _vm.$checkPermission('task.create')
            )?_c('v-menu',{attrs:{"position-x":_vm.contextMenu.x,"position-y":_vm.contextMenu.y,"absolute":"","offset-y":"","close-on-content-click":true},model:{value:(_vm.contextMenu.show),callback:function ($$v) {_vm.$set(_vm.contextMenu, "show", $$v)},expression:"contextMenu.show"}},[_c('div',{staticClass:"v-menu-body"},[(_vm.contextMenu.subscription)?_c('ActionContextMenu',{attrs:{"custom":[
                        {
                            permission: _vm.$checkPermission('project.update'),
                            label: 'Edit',
                            url: ("/projects/" + (_vm.contextMenu.subscription.project.id) + "/details"),
                            icon: 'la-cog',
                        },
                        {
                            type: 'project-folder',
                            label: 'Open folder',
                            url: _vm.$folderPath(_vm.contextMenu.subscription.project.relPath),
                            icon: 'la-folder-open',
                        } ],"task":{
                        entityId: _vm.contextMenu.subscription.project.id,
                        entityTypeId: 2,
                    },"message":{
                        entityId: _vm.contextMenu.subscription.project.id,
                        entityTypeId: 2,
                    }}}):_vm._e()],1)]):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"shadow-line shadow-line-after"})])}
var staticRenderFns = []

export { render, staticRenderFns }