<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="540"
        persistent
    >
        <div class="dialog-body">
            <modal-header text="Send message" icon="la-paper-plane" @closeModal="closeModal" />

            <validation-observer ref="observer" v-slot="{ invalid }">
                <v-form @submit.prevent="onSave">
                    <div class="mb-4">
                        <label class="form-label">Send to*</label>
                        <validation-provider v-slot="{ errors }" vid="recipients" name="Assignee">
                            <v-autocomplete
                                v-if="users && me"
                                v-model="message.assigned_to"
                                name="assigned_to"
                                :items="sortedUsers"
                                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                :error-messages="errors"
                                :menu-props="{ closeOnContentClick: true }"
                                class="user-badges"
                                label="Send message to"
                                multiple
                                solo
                                dense
                                chips
                                return-object
                                deletable-chips
                            >
                                <template #selection="datas">
                                    <v-chip
                                        v-bind="datas.attrs"
                                        :input-value="datas.selected"
                                        close
                                        @click="datas.select"
                                        @click:close="removeItem(datas.item)"
                                    >
                                        <user-badge :user="datas.item" is-small full-name />
                                    </v-chip>
                                </template>
                                <template #item="{ item }">
                                    <user-badge :user="item" full-name />
                                </template>
                            </v-autocomplete>
                        </validation-provider>

                        <div class="my-4">
                            <base-switch
                                label="Send emails"
                                name="send_emails_switch"
                                :value="message.sendEmails"
                                @input="(val) => { message.sendEmails = val; }"
                            />
                        </div>
                    </div>

                    <!-- <div class="grid gap-4" :class="message.type && message.type.id !== 1 && message.type.id !== 2 ? 'grid-cols-3' : 'grid-cols-2'"> -->
                    <div
                        class="grid gap-4"
                        :class="
                            message.type && [1, 2].includes(message.type.id)
                                ? `grid-cols-${message.type.id}`
                                : 'grid-cols-3'
                        "
                    >
                        <div class="mb-4">
                            <label class="form-label">Type*</label>
                            <validation-provider v-slot="{ errors }" name="Message type" rules="required">
                                <v-select
                                    v-model="message.type"
                                    solo
                                    dense
                                    return-object
                                    :items="messageTypes"
                                    :item-text="(item) => item.label"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </div>

                        <div v-if="message.type && message.type.id !== 1 && message.type.id !== 2" class="mb-4">
                            <label class="form-label">Project*</label>
                            <div class="relative">
                                <validation-provider v-slot="{ errors }" name="Project" rules="required">
                                    <v-select
                                        v-model="message.project"
                                        solo
                                        dense
                                        return-object
                                        :items="projects_list"
                                        :error-messages="errors"
                                        @change="changeProject()"
                                    >
                                        <template #selection="{ item }">
                                            <project-badge :project="item" />
                                        </template>
                                        <template #item="{ item }">
                                            <project-badge :project="item" />
                                        </template>
                                    </v-select>
                                    <transition name="fade">
                                        <div
                                            v-if="isProjectLoading"
                                            class="absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 bg-gray-300 rounded"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>
                        </div>

                        <div v-if="message.type && message.type.id !== 1" class="mb-4">
                            <label class="form-label">{{ message.type.label }}*</label>
                            <div class="relative">
                                <validation-provider v-slot="{ errors }" name="Entity" rules="required">
                                    <v-select
                                        v-model="message.entity"
                                        solo
                                        dense
                                        return-object
                                        :items="messageEntityArray"
                                        :error-messages="errors"
                                        :disabled="message.type.id !== 1 && message.type.id !== 2 && !message.project"
                                        @change="() => {if (message.type.id === 2) loadPriorities()}"
                                    >
                                        <template #selection="{ item }">
                                            <project-badge v-if="message.type.id === 2" :project="item" />
                                            <span v-else class="dark:text-white">{{ getEntityItemName(item) }}</span>
                                        </template>
                                        <template #item="{ item }">
                                            <project-badge v-if="message.type.id === 2" :project="item" />
                                            <span v-else class="dark:text-white">{{ getEntityItemName(item) }}</span>
                                        </template>
                                    </v-select>

                                    <transition name="fade">
                                        <div
                                            v-if="isEntityLoading"
                                            class="absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 bg-gray-300 rounded"
                                        >
                                            <i class="animate-spin las la-circle-notch text-xl" />
                                        </div>
                                    </transition>
                                </validation-provider>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <label class="form-label">Message*</label>
                        <validation-provider v-slot="{ errors }" vid="payload" name="Message">
                            <ck-editor
                                v-if="allowCkeditor"
                                v-model="message.message"
                                slim
                                set-focus
                                :error-messages="errors"
                            />
                        </validation-provider>
                    </div>

                    <footer class="dialog-footer">
                        <div class="col-span-2">
                            <v-btn block @click="closeModal">Cancel</v-btn>
                        </div>
                        <div class="col-start-3 col-span-3">
                            <v-btn
                                block
                                color="primary"
                                :disabled="invalid || !message.message || !message.assigned_to.length || postInProgress"
                                type="submit"
                            >
                                <i class="las la-paper-plane mr-2" />
                                Send
                            </v-btn>
                        </div>
                    </footer>
                </v-form>
            </validation-observer>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        showModal: { type: Boolean, required: false },
    },
    data() {
        return {
            allowCkeditor: false,
            isEntityLoading: false,
            isProjectLoading: false,
            projects_list: [],
            messageEntityArray: [],
            message: {
                project: null,
                user: null,
                type: null,
                message: '',
                assigned_to: [],
                sendEmails: true,
            },
            data: null,
            postInProgress: false,
            priorityList: null,
        };
    },
    computed: {
        ...mapGetters({
            config: 'config/getConfig',
            users: 'config/getUsers',
            me: 'users/me/getMe',
            messageType: 'messages/getMessageType',
            messageEntityID: 'messages/getMessageEntityID',
            messageProject: 'messages/getMessageProject',
            messageRecipient: 'messages/getMessageRecipient',
        }),
        dialog: {
            get() {
                return this.showModal;
            },
            set(value) {
                this.$store.commit('messages/setShowNewMessage', value);
                this.$refs.observer.reset();
            },
        },
        messageTypes() {
            return this.$getMessageTypesFilteredByUserPermission();
        },
        sortedUsers: {
            get() {
                if (this.priorityList) {
                    const idList = this.priorityList.map(x => x.id);
                    return this.users.filter(x => idList.includes(x.id)).concat(
                        this.users.filter(x => !idList.includes(x.id))
                    );
                } else {
                    return this.users;
                }
            }
        },
    },
    watch: {
        showModal(isOpen) {
            if (isOpen === true) {
                setTimeout(() => {
                    this.allowCkeditor = true;
                }, 300);

                if (this.messageType) {
                    const messageTypeFoundInPermittedMessageTypes = this.messageTypes.find(
                        (data) => data.id === this.messageType
                    );
                    if (messageTypeFoundInPermittedMessageTypes) {
                        this.message.type = messageTypeFoundInPermittedMessageTypes;
                    } else {
                        this.message.type = this.messageTypes.find((type) => type.id === 1);
                    }
                } else {
                    this.message.type = this.messageTypes.find((type) => type.id === 1);
                }

                if (this.messageRecipient) {
                    this.message.assigned_to.push(this.users.find((data) => data.id === this.messageRecipient));
                }

                switch (this.message.type.id) {
                    case 1:
                        this.messageEntityArray = this.users;
                        if (this.messageEntityID) {
                            setTimeout(() => {
                                this.message.entity = this.users.find((data) => data.id === this.messageEntityID);
                            });
                        }
                        break;
                    case 2:
                        this.loadProjects();
                        break;
                    case 3:
                        this.loadProjects('category');
                        this.loadBids(this.messageProject);
                        break;
                    case 4:
                        this.loadProjects('category');
                        this.loadResources(this.messageProject);
                        break;
                    case 5:
                        this.loadProjects('category');
                        this.loadShots(this.messageProject);
                        break;
                    case 6:
                        this.loadProjects('category');
                        this.loadDailies(this.messageProject);
                        break;
                    case 7:
                        this.loadProjects('category');
                        this.loadBroadcasts(this.messageProject);
                        break;
                    case 8:
                        this.loadProjects('category');
                        this.loadSubmits(this.messageProject);
                        break;
                    case 9:
                        this.loadProjects('category');
                        this.loadTasks(this.messageProject);
                        break;
                }
            } else {
                setTimeout(() => {
                    this.messageEntityArray = [];
                    this.message.entity = null;
                    this.message.message = '';
                    this.message.assigned_to = [];
                    this.message.sendEmails = true;
                    this.$store.commit('messages/setMessageEntityID', null);
                    this.$store.commit('messages/setMessageType', null);
                    this.$store.commit('messages/setMessageRecipient', null);
                    this.$refs.observer.reset();
                    this.allowCkeditor = false;
                    this.priorityList = null;
                }, 500);
            }
        },
        'message.type'(e) {
            this.messageEntityArray = [];
            this.message.entity = null;
            if (e) {
                switch (e.id) {
                    case 1:
                        this.messageEntityArray = this.users;
                        break;
                    case 2:
                        this.loadProjects();
                        break;
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        this.loadProjects('category', 'change');
                        break;
                }
            }
        },
        'message.message'(e) {
            if (e.length >= 3) {
                this.$refs.observer.validate();
            }
        },
        'message.project'(e) {
            if (e) {
                this.loadPriorities();
            }
        },
    },
    created() {
        this.loadProjects = this.$_.debounce(this.loadProjects);
        this.loadBids = this.$_.debounce(this.loadBids);
        this.loadResources = this.$_.debounce(this.loadResources);
        this.loadShots = this.$_.debounce(this.loadShots);
        this.loadSubmits = this.$_.debounce(this.loadSubmits);
        this.loadDailies = this.$_.debounce(this.loadDailies);
        this.loadBroadcasts = this.$_.debounce(this.loadBroadcasts);
        this.loadTasks = this.$_.debounce(this.loadTasks);
        this.changeProject = this.$_.debounce(this.changeProject, 500);
    },
    methods: {
        async loadProjects(type, state) {
            this.isEntityLoading = true;
            this.isProjectLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/projects?order=name&filters[]=overLimit:eq:true`)
                .then((res) => {
                    if (type === 'category') {
                        this.projects_list = this.$clone(res.result);
                        this.message.project = this.projects_list.find(
                            (data) => data.id === parseInt(this.messageProject)
                        );
                        if (state === 'change') {
                            this.changeProject();
                        }
                        this.isProjectLoading = false;
                    } else {
                        this.messageEntityArray = this.$clone(res.result);
                        this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    }
                    this.isEntityLoading = false;
                    this.$refs.observer.reset();
                    this.loadPriorities();
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadBids(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/bids`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadResources(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/resources`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadShots(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `sequence.project:eq:${projectID}`);
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/shots`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadSubmits(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `sequence.project:eq:${projectID}`);
            }
            params.append('limit', `2000`);
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/submits`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        pass: value.pass,
                        version: value.version,
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadDailies(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
                params.append('scopes[]', 'with_pass_info');
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/dailies`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: this.getGeneratedDailyID(value),
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadBroadcasts(projectID) {
            const params = new URLSearchParams();
            if (projectID) {
                params.append('filters[]', `project:eq:${projectID}`);
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/broadcasts`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => {
                        return {
                            id: value.id,
                            name: value.preset ? value.preset.name : '[no preset]',
                        };
                    });

                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        async loadTasks(projectId) {
            const params = new URLSearchParams();
            if (projectId) {
                params.append('filters[]', `project:eq:${projectId}`);
                params.append('filters[]', `noDispo:eq:true`);
                params.append('filters[]', `overLimit:eq:true`);
                params.append('limit', 500);
            }
            this.isEntityLoading = true;
            await this.$axios
                .$get(`${process.env.prodAPI}/tasks`, { params })
                .then((res) => {
                    this.messageEntityArray = Object.entries(res.result).map(([_, value]) => ({
                        id: value.id,
                        name: value.name,
                    }));
                    this.message.entity = this.messageEntityArray.find((data) => data.id === this.messageEntityID);
                    this.isEntityLoading = false;
                })
                .catch((e) => {
                    this.$notify({ title: e.response.data.message, type: 'error' });
                });
        },
        closeModal() {
            this.$store.commit('messages/setShowNewMessage', false);
        },
        onSave() {
            this.postInProgress = true;
            const postData = new FormData();
            if (this.message.type.id !== 1 && this.message.entity) {
                postData.append('entityId', this.message.entity.id);
            }
            postData.append('type', this.message.type.id);
            postData.append('payload', this.message.message);
            for (const user of this.message.assigned_to) {
                if (user !== undefined) {
                    postData.append('recipients[]', user.id);
                }
            }
            postData.append('noMail', !this.message.sendEmails);

            this.$axios
                .$post(`${process.env.prodAPI}/messages/send`, postData)
                .then((res) => {
                    this.$notify({ title: res.message, type: 'info' });
                    this.$store.commit('messages/setShowNewMessage', false);
                    this.$store.commit('messages/setRefreshMessagesTable', true);
                })
                .catch((err) => {
                    this.$refs.observer.setErrors(this.$formatErrorMessages(err.response.data.errors));
                })
                .finally(() => {
                    this.postInProgress = false;
                });
        },
        removeItem(item) {
            this.message.assigned_to = this.message.assigned_to.filter((data) => data && data.id !== item.id);
        },
        changeProject() {
            switch (this.message.type.id) {
                case 3:
                    if (this.message.project) {
                        this.loadBids(this.message.project.id);
                    }
                    break;
                case 4:
                    if (this.message.project) {
                        this.loadResources(this.message.project.id);
                    }
                    break;
                case 5:
                    if (this.message.project) {
                        this.loadShots(this.message.project.id);
                    }
                    break;
                case 6:
                    if (this.message.project) {
                        this.loadDailies(this.message.project.id);
                    }
                    break;
                case 7:
                    if (this.message.project) {
                        this.loadBroadcasts(this.message.project.id);
                    }
                    break;
                case 8:
                    if (this.message.project) {
                        this.loadSubmits(this.message.project.id);
                    }
                    break;
                case 9:
                    if (this.message.project) {
                        this.loadTasks(this.message.project.id);
                    }
                    break;
            }
        },
        getGeneratedDailyID(daily) {
            const clientVersion = daily.clientVersion ? ' ' + daily.clientVersion : '';
            const preset = daily.preset ? ' (' + daily.preset + ')' : '';
            return `${daily.submit.pass.name} ${clientVersion} ${preset} #${daily.id}`;
        },
        getEntityItemName(item) {
            return item.fullName || item.name || `${item.pass.type} / ${item.pass.name} / v${item.version}`;
        },
        loadPriorities() {
            console.log('Loading priorities!');
            if (this.message?.type?.id !== 1) {
                const id = this.message?.project?.id ?
                    this.message.project.id : // Priority 1
                    (this.message?.type?.id === 2) ?
                        this.message?.entity?.id : // Priority 2
                        this.messageProject; // Priority 3
                if (id) {
                    this.$axios
                        .$get(`${process.env.prodAPI}/project-members?filters[]=project:eq:${id}`)
                        .then((res) => {
                            this.priorityList = res.result.map(pm => pm.user);
                        })
                        .catch((e) => {
                            if (e?.response?.data?.errors) {
                                for (const error of e.response.data.errors) {
                                    this.$notify({
                                        title: e.response.data.message,
                                        text: `${error.key}: ${error.message}`,
                                        type: 'error',
                                    });
                                }
                            } else {
                                this.$notify({
                                    title: 'Unknown error occurred',
                                    type: 'error',
                                });
                            }
                        });
                }
            } else {
                this.priorityList = null;
            }
        }
    },
};
</script>
