export default async function ({ app, store, route, error }) {
    let userPermissions = app.store.getters['users/me/getPermissions'];

    const requests = [];

    // basically this condition checks if the user is logged in already
    if (!userPermissions) {
        requests.push(store.dispatch('users/me/setMe'));
        requests.push(store.dispatch('config/fetchConfig'));
    }

    // in order for the app to open faster, we make all the api calls run concurrently here, and not separately in their respective components
    if (/projects-id/.test(route.name) && route.params.id) {
        const activeProjectId = store.state.project.activeProject.id;
        const activeShotId = store.state.project.activeShot.id;
        const activeMessagesTab = store.state.messages.tab;

        // Fetch project if needed
        if (!activeProjectId || String(activeProjectId) !== route.params.id) {
            store.commit('project/setIsActiveProjectLoading', true);
            requests.push(store.dispatch('project/fetchActiveProject', route.params.id));
            requests.push(store.dispatch('project/fetchActiveProjectMembers', route.params.id));
            requests.push(store.dispatch('project/fetchSequences', route.params.id));
        }

        // Fetch shot & passes if the selected tab is "shots"
        if (route.params.shot && (!activeShotId || String(activeShotId) !== route.params.shot)) {
            store.commit('project/setIsActiveShotLoading', true);
            requests.push(store.dispatch('project/fetchActiveShot', route.params.shot));
            requests.push(store.dispatch('project/fetchPasses', { shotID: route.params.shot, type: 'comp' }));
            requests.push(store.dispatch('project/fetchPasses', { shotID: route.params.shot, type: 'cg' }));
            requests.push(store.dispatch('project/fetchPasses', { shotID: route.params.shot, type: 'image' }));
            requests.push(store.dispatch('project/fetchPasses', { shotID: route.params.shot, type: 'audio' }));

            store.commit('routing/setSavedProjectShotRoute', {
                projectID: route.params.id,
                shotRoute: '/' + route.params.shot,
            });
        }

        if (!route.fullPath.includes(activeMessagesTab) && route.fullPath.split('/').slice(-2,-1)[0] === 'messages') {
            store.commit('messages/setIsPageLoading', true);
            const routeParts = route.fullPath.split('/');
            requests.push(store.dispatch('messages/fetchMessageTab', routeParts[routeParts.length - 1]));
        }
    }

    if (requests.length) {
        await Promise.all(requests);
        store.commit('project/setIsActiveProjectLoading', false);
        store.commit('project/setIsActiveShotLoading', false);
        store.commit('messages/setIsPageLoading', false);
        userPermissions = store.getters['users/me/getPermissions'];
    }

    const userPermissionHandles = app.$clone(userPermissions).map((x) => x.handle);
    const routePermissions = store.getters['permissions/getPermissions'];
    const routeHandler = routePermissions.find((data) => data.route === route.name);

    // if user has all the permissions, don't do anything
    if (!routeHandler || routeHandler.handle.every((x) => userPermissionHandles.includes(x))) return;
    const missingPermissions = routeHandler.handle.filter((x) => !userPermissionHandles.includes(x));

    if (/projects-id/.test(routeHandler.route)) {
        store.commit('routing/setSavedProjectTabRoute', {
            projectID: route.params.id,
            tabRoute: null,
        });
    }

    return error({
        statusCode: 403,
        message: `Access denied (${missingPermissions.join(', ')} permission required)`,
    });
}
