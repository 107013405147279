var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-opacity":_vm.$store.state.vuetify.dialog.overlayOpacity,"overlay-color":_vm.$store.state.vuetify.dialog.overlayColor,"width":"540","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialog-body"},[_c('modal-header',{attrs:{"text":"Send message","icon":"la-paper-plane"},on:{"closeModal":_vm.closeModal}}),_vm._v(" "),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Send to*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"recipients","name":"Assignee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.users && _vm.me)?_c('v-autocomplete',{staticClass:"user-badges",attrs:{"name":"assigned_to","items":_vm.sortedUsers,"item-text":function (item) { return item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); },"error-messages":errors,"menu-props":{ closeOnContentClick: true },"label":"Send message to","multiple":"","solo":"","dense":"","chips":"","return-object":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(datas){return [_c('v-chip',_vm._b({attrs:{"input-value":datas.selected,"close":""},on:{"click":datas.select,"click:close":function($event){return _vm.removeItem(datas.item)}}},'v-chip',datas.attrs,false),[_c('user-badge',{attrs:{"user":datas.item,"is-small":"","full-name":""}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('user-badge',{attrs:{"user":item,"full-name":""}})]}}],null,true),model:{value:(_vm.message.assigned_to),callback:function ($$v) {_vm.$set(_vm.message, "assigned_to", $$v)},expression:"message.assigned_to"}}):_vm._e()]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"my-4"},[_c('base-switch',{attrs:{"label":"Send emails","name":"send_emails_switch","value":_vm.message.sendEmails},on:{"input":function (val) { _vm.message.sendEmails = val; }}})],1)],1),_vm._v(" "),_c('div',{staticClass:"grid gap-4",class:_vm.message.type && [1, 2].includes(_vm.message.type.id)
                            ? ("grid-cols-" + (_vm.message.type.id))
                            : 'grid-cols-3'},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Type*")]),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Message type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","items":_vm.messageTypes,"item-text":function (item) { return item.label; },"error-messages":errors},model:{value:(_vm.message.type),callback:function ($$v) {_vm.$set(_vm.message, "type", $$v)},expression:"message.type"}})]}}],null,true)})],1),_vm._v(" "),(_vm.message.type && _vm.message.type.id !== 1 && _vm.message.type.id !== 2)?_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Project*")]),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","items":_vm.projects_list,"error-messages":errors},on:{"change":function($event){return _vm.changeProject()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
return [_c('project-badge',{attrs:{"project":item}})]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('project-badge',{attrs:{"project":item}})]}}],null,true),model:{value:(_vm.message.project),callback:function ($$v) {_vm.$set(_vm.message, "project", $$v)},expression:"message.project"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isProjectLoading)?_c('div',{staticClass:"absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 bg-gray-300 rounded"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1)]):_vm._e(),_vm._v(" "),(_vm.message.type && _vm.message.type.id !== 1)?_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.message.type.label)+"*")]),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('validation-provider',{attrs:{"name":"Entity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"solo":"","dense":"","return-object":"","items":_vm.messageEntityArray,"error-messages":errors,"disabled":_vm.message.type.id !== 1 && _vm.message.type.id !== 2 && !_vm.message.project},on:{"change":function () {if (_vm.message.type.id === 2) { _vm.loadPriorities() }}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var item = ref.item;
return [(_vm.message.type.id === 2)?_c('project-badge',{attrs:{"project":item}}):_c('span',{staticClass:"dark:text-white"},[_vm._v(_vm._s(_vm.getEntityItemName(item)))])]}},{key:"item",fn:function(ref){
                            var item = ref.item;
return [(_vm.message.type.id === 2)?_c('project-badge',{attrs:{"project":item}}):_c('span',{staticClass:"dark:text-white"},[_vm._v(_vm._s(_vm.getEntityItemName(item)))])]}}],null,true),model:{value:(_vm.message.entity),callback:function ($$v) {_vm.$set(_vm.message, "entity", $$v)},expression:"message.entity"}}),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isEntityLoading)?_c('div',{staticClass:"absolute top-0 left-0 w-full h-10 z-10 flex items-center pl-4 bg-gray-300 rounded"},[_c('i',{staticClass:"animate-spin las la-circle-notch text-xl"})]):_vm._e()])]}}],null,true)})],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Message*")]),_vm._v(" "),_c('validation-provider',{attrs:{"vid":"payload","name":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [(_vm.allowCkeditor)?_c('ck-editor',{attrs:{"slim":"","set-focus":"","error-messages":errors},model:{value:(_vm.message.message),callback:function ($$v) {_vm.$set(_vm.message, "message", $$v)},expression:"message.message"}}):_vm._e()]}}],null,true)})],1),_vm._v(" "),_c('footer',{staticClass:"dialog-footer"},[_c('div',{staticClass:"col-span-2"},[_c('v-btn',{attrs:{"block":""},on:{"click":_vm.closeModal}},[_vm._v("Cancel")])],1),_vm._v(" "),_c('div',{staticClass:"col-start-3 col-span-3"},[_c('v-btn',{attrs:{"block":"","color":"primary","disabled":invalid || !_vm.message.message || !_vm.message.assigned_to.length || _vm.postInProgress,"type":"submit"}},[_c('i',{staticClass:"las la-paper-plane mr-2"}),_vm._v("\n                            Send\n                        ")])],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }