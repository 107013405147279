import moment from 'moment';

const getDefaultState = () => ({
    me: {},
    projects: [],
    formattedProjects: [],
    notifications: [],
    newNotification: false,
    refreshNotificationsTable: false,
    permissions: null,
    unloadBlockers: [],
    screenSize: 0,
});

export const state = () => getDefaultState();

export const getters = {
    getMe(state) {
        return state.me;
    },
    getActiveProjectSubscriptions(state) {
        return state.projects.filter((subscription) => {
            return (
                (!subscription.project.deadline ||
                    moment(subscription.project.deadline).isSameOrAfter(moment(), 'day')) &&
                (subscription.subscribedUntil === null ||
                    moment(subscription.subscribedUntil).isSameOrAfter(moment(), 'day')) &&
                subscription.project.status !== 'archived' &&
                subscription.project.status !== 'restoration_in_progress' &&
                subscription.project.status !== 'archive_in_progress'
            );
        });
    },
    getFormattedProjects(state) {
        return state.formattedProjects;
    },
    getNotifications(state) {
        return state.notifications;
    },
    getNewNotification(state) {
        return state.newNotification;
    },
    getRefreshNotificationsTable(state) {
        return state.refreshNotificationsTable;
    },
    getPermissions(state) {
        return state.permissions;
    },
    getUnloadBlockers(state) {
        return state.unloadBlockers;
    },
    getScreenSize(state) {
        return state.screenSize;
    }
};

export const mutations = {
    setMe(state, me) {
        state.me = me;
    },
    setProjects(state, projects) {
        state.projects = projects;
    },
    setProjectsFormatted(state, projects) {
        for (const submission of projects) {
            if (state.formattedProjects.find((x) => x.id === submission.project.id)) {
                return;
            }

            state.formattedProjects.push(submission.project);
        }
    },
    deleteProject(state, projectID) {
        state.projects = state.projects.filter((data) => data.project.id !== projectID);
    },
    setNotifications(state, notifications) {
        state.notifications = notifications;
    },
    setNewNotification(state, newNotification) {
        state.newNotification = newNotification;
    },
    setRefreshNotificationsTable(state, status) {
        state.refreshNotificationsTable = status;
        this.$store.dispatch('users/me/setMe');
    },
    setPermissions(state, permissions) {
        state.permissions = permissions;
    },
    resetState(state) {
        Object.assign(state, getDefaultState());
        // don't replace this with state = getDefaultState()
        // https://github.com/vuejs/vuex/issues/1118
    },
    addUnloadBlocker(state, item) {
        state.unloadBlockers.push(item);
    },
    removeUnloadBlocker(state, item) {
        state.unloadBlockers = state.unloadBlockers.filter((x) => x !== item);
    },
    setScreenSize(state, size) {
        state.screenSize = size;
    },
    toggleFavoriteUser(state, payload) {
        if (state.me.favoriteUsers.includes(payload.id)) {
            state.me.favoriteUsers = state.me.favoriteUsers.filter((x) => x !== payload.id);
        } else {
            state.me.favoriteUsers.push(payload.id);
        }
        const postData = new FormData();
        if (state.me.favoriteUsers.length > 0) {
            state.me.favoriteUsers.forEach((user) => {
                postData.append('favoriteUsers[]', user);
            });
        } else {
            postData.append('favoriteUsers[]', []);
        }
        this.$axios
            .$post(`${process.env.prodAPI}/me/favorite-users`, postData)
            .then(() => {
                payload.api.refetchResources();
            })
            .catch((e) => {
                for (const error of e.response.data.errors) {
                    this.$notify({
                        title: e.response.data.message,
                        text: `${error.key}: ${error.message}`,
                        type: 'error'
                    });
                }
            });
    },
};

export const actions = {
    reset({ commit }) {
        commit('resetState');
    },
    async setMe({ commit }) {
        return await this.$axios
            .$get(`${process.env.prodAPI}/me?scopes[]=with_project_subscriptions&scopes[]=with_favorite_users`)
            .then((res) => {
                commit('setMe', res.result.user);
                commit('setPermissions', res.result.grantedPermissions);
                commit('setProjects', res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []);
                commit(
                    'setProjectsFormatted',
                    res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []
                );
                commit('setNotifications', res.result.recentNotifications);
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
    async setMyProjects(context) {
        await this.$axios
            .$get(`${process.env.prodAPI}/me?scopes[]=with_project_subscriptions`)
            .then((res) => {
                context.commit(
                    'setProjects',
                    res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []
                );
                context.commit(
                    'setProjectsFormatted',
                    res.result.user.projectSubscriptions ? res.result.user.projectSubscriptions : []
                );
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
};
