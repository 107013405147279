<template>
    <nav class="sidebar-menu-alt absolute bottom-0 left-0 w-full px-4">
        <div v-if="!$user.roles.includes('ROLE_ARTIST')" class="overflow-hidden">
            <button
                v-if="filterItems(managementItemSourceArray).length"
                type="button"
                :class="linkClasses"
                class="open-admin"
                title="Open Management menu"
            >
                <span
                    class="h-9 w-9 min-w-9 text-2xl rounded-full flex items-center justify-center mr-4 transition-all text-primary"
                    :class="{ 'ml-0.5': !sidebarOpen }"
                >
                    <i class="las la-file-alt" />
                </span>
                <transition name="fade">
                    <span v-if="sidebarOpen" class="font-gotham-500 text-xs">Manage</span>
                </transition>
                <transition name="fade">
                    <v-btn v-if="sidebarOpen" class="ml-auto text-2xl" icon x-small>
                        <i
                            class="las la-ellipsis-v transition-colors text-gray-600 hover:text-secondary dark:text-white"
                        />
                    </v-btn>
                </transition>
            </button>

            <v-menu
                v-if="filterItems(managementItemSourceArray).length"
                :close-on-content-click="true"
                offset-y
                activator=".open-admin"
                transition="slide-x-transition"
                z-index="30"
            >
                <div class="v-menu-body">
                    <ul>
                        <li v-for="item in filterItems(managementItemSourceArray)" :key="item.id" class="group">
                            <nuxt-link
                                :to="item.url"
                                class="font-gotham-500 text-white h-12 flex items-center min-w-36 transition-all hover:pl-1 border-b border-gray-600 group-last:border-0"
                            >
                                <i :class="item.icon" class="h-6 w-6 inline-flex items-center justify-center mr-1" />
                                <span>{{ item.name }}</span>
                            </nuxt-link>
                        </li>
                    </ul>
                </div>
            </v-menu>

            <hr v-if="filterItems(settingsItemSourceArray).length && filterItems(managementItemSourceArray).length"
                class="border-gray-300 dark:border-dark-800 transition-all w-full" />

            <button
                v-if="filterItems(settingsItemSourceArray).length"
                type="button"
                :class="linkClasses"
                class="open-settings"
                title="Open System menu"
            >
                <span
                    class="h-9 w-9 min-w-9 text-2xl rounded-full flex items-center justify-center mr-4 transition-all text-primary"
                    :class="{ 'ml-0.5': !sidebarOpen }"
                >
                    <i class="las la-cogs" />
                </span>
                <transition name="fade">
                    <span v-if="sidebarOpen" class="font-gotham-500 text-xs">System</span>
                </transition>
                <transition name="fade">
                    <v-btn v-if="sidebarOpen" class="ml-auto text-2xl" icon x-small>
                        <i
                            class="las la-ellipsis-v transition-colors text-gray-600 hover:text-secondary dark:text-white"
                        />
                    </v-btn>
                </transition>
            </button>
            <v-menu
                v-if="filterItems(settingsItemSourceArray).length"
                :close-on-content-click="true"
                offset-y
                activator=".open-settings"
                transition="slide-x-transition"
                z-index="30"
            >
                <div class="v-menu-body">
                    <ul>
                        <li v-for="item in filterItems(settingsItemSourceArray)" :key="item.id" class="group">
                            <nuxt-link
                                :to="item.url"
                                class="font-gotham-500 text-white h-12 flex items-center min-w-36 transition-all hover:pl-1 border-b border-gray-600 group-last:border-0"
                            >
                                <i :class="item.icon" class="h-6 w-6 inline-flex items-center justify-center mr-1" />
                                <span>{{ item.name }}</span>
                            </nuxt-link>
                        </li>
                    </ul>
                </div>
            </v-menu>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            linkClasses: 'py-4 w-100 flex items-center transition-colors duration-300 relative w-full text-left',
            managementItemSourceArray: [
                {
                    name: 'Tasks',
                    url: '/tasks',
                    icon: 'las la-check-square',
                    permission: 'task.read_list'
                },
                {
                    name: 'Projects',
                    url: '/projects',
                    icon: 'las la-stream',
                    permission: 'project.read_list',
                },
                {
                    name: 'Dispo',
                    url: '/dispo',
                    icon: 'las la-calendar',
                    permission: ['dispo_entry.read_list', 'user.read_list'],
                },
                {
                    name: 'Clients',
                    url: '/manage/clients',
                    icon: 'las la-user-tie',
                    permission: 'client.read_list',
                },
                {
                    name: 'Agencies',
                    url: '/manage/agencies',
                    icon: 'las la-briefcase',
                    permission: 'agency.read_list',
                },
                // {
                //     name: 'Comparison',
                //     url: '/comparison',
                //     icon: 'las la-balance-scale'
                // },
                {
                    name: 'Contacts',
                    url: '/manage/contacts',
                    icon: 'las la-address-book',
                    permission: 'contact.read_list',
                },
                {
                    name: 'Brands',
                    url: '/manage/brands',
                    icon: 'las la-copyright',
                    permission: 'brand.read_list',
                },
            ],
            settingsItemSourceArray: [
                {
                    name: 'System log',
                    url: '/system/system-log',
                    icon: 'las la-book',
                    permission: 'system_log.read',
                },
                {
                    name: 'Presets',
                    url: '/system/presets',
                    icon: 'las la-clock',
                    permission: 'preset.read_list',
                },
                {
                    name: 'Tags',
                    url: '/system/tags',
                    icon: 'las la-tag',
                    permission: 'tag.read_list'
                },
                {
                    name: 'Users',
                    url: '/users',
                    icon: 'las la-users-cog',
                    permission: 'user.read_list',
                    adminAndITOnly: true,
                },
                {
                    name: 'Permissions',
                    url: '/system/permissions',
                    icon: 'las la-key',
                    permission: 'permission.read_list',
                },
                {
                    name: 'Settings',
                    url: '/system/settings',
                    icon: 'las la-cog',
                    adminAndITOnly: true
                },
                {
                    name: 'Feedbacks',
                    url: '/system/feedbacks',
                    icon: 'las la-bug',
                    adminAndITOnly: true
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            sidebarOpen: 'preferences/getSidebarState',
            adminMenuOpen: 'getSidebarAdminState',
            permissions: 'users/me/getPermissions',
        }),
    },
    methods: {
        filterItems(itemSource) {
            const output = [];
            itemSource.forEach((item) => {
                if (this.checkItemVisibility(item)) {
                    item.id = output.length + 1;
                    output.push(item);
                }
            });
            return output;
        },
        checkItemVisibility(item) {
            let isAllowedByRole = false;
            let isAllowedByPermission = false;

            if (
                !item.adminAndITOnly ||
                (item.adminAndITOnly &&
                    (this.$user.roles.includes('ROLE_ADMIN') || this.$user.roles.includes('ROLE_IT')))
            ) {
                isAllowedByRole = true;
            }

            if (!item.permission || this.$checkPermission(item.permission)) {
                isAllowedByPermission = true;
            }

            return isAllowedByRole && isAllowedByPermission;
        },
    },
};
</script>

<style scoped lang="postcss">
.sidebar-menu-alt {
    &:before {
        content: '';
        @apply absolute -top-2 left-0 w-full h-2;
        @apply bg-gradient-to-t;
        @apply from-gray-150 dark:from-dark-900 to-transparent;
    }
}

.main .v-menu__content {
    @apply bottom-0;
    top: auto !important;
}

.admin_open {
    &-enter,
    &-leave-to {
        @apply opacity-0 max-h-0;
        @apply transform translate-y-3;
    }

    &-enter-active,
    &-leave-active {
        @apply transition-all;
    }

    &-enter-to,
    &-leave {
        @apply opacity-100 max-h-80;
        @apply transform translate-y-0;
    }
}
</style>
