const getInitialState = () => ({
    isActiveProjectLoading: false,
    isActiveShotLoading: false,
    activeProject: {},
    activeShot: {},
    activePasses: {},
    sequences: [],
    team: [],
    isNewProjectModalOpen: false,
    newColorSpace: null,
});

export const state = () => getInitialState();

export const getters = {
    getTeam(state) {
        return state.team;
    },
    getActiveProjectSequences(state) {
        return state.sequences;
    },
    getActiveProject(state) {
        return state.activeProject;
    },
    getActiveShot(state) {
        return state.activeShot;
    },
    getNewColorSpace(state) {
        return state.newColorSpace;
    }
};

export const mutations = {
    setIsActiveProjectLoading(state, payload) {
        state.isActiveProjectLoading = payload;
    },
    setIsActiveShotLoading(state, payload) {
        state.isActiveShotLoading = payload;
    },
    setActiveProject(state, payload) {
        state.activeProject = payload;
    },
    setSequences(state, payload) {
        state.sequences = payload;
    },
    setActiveShot(state, payload) {
        state.activeShot = payload;
    },
    setActivePasses(state, payload) {
        state.activePasses[payload.type] = payload.data;
    },
    setTeam(state, payload) {
        state.team = payload;
    },
    setNewProjectModalVisibility(state, payload) {
        state.isNewProjectModalOpen = payload;
    },
    setNewColorSpace(state, payload) {
        state.newColorSpace = payload;
    },
    reset(state) {
        Object.keys(state).forEach((key) => {
            state[key] = getInitialState()[key];
        });
    },
};

export const actions = {
    async fetchActiveProject({ commit }, projectID) {
        return await this.$axios
            .$get(
                `${process.env.prodAPI}/projects/${projectID}?scopes[]=with_milestone_info&scopes[]=with_resource_categories&scopes[]=created_meta`
            )
            .then((res) => {
                commit('setActiveProject', res.result);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    this.$router.push(`/`);
                } else {
                    const error = new Error(e.response.data.message);
                    error.statusCode = e.response.status;
                    throw error;
                }
            });
    },
    async fetchActiveProjectMembers({ commit }, projectID) {
        return await this.$axios
            .$get(`${process.env.prodAPI}/project-members?filters[]=project:eq:${projectID}`)
            .then((res) => {
                commit('setTeam', res.result);
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
    async fetchActiveShot({ commit }, shotID) {
        return await this.$axios.$get(`${process.env.prodAPI}/shots/${shotID}?scopes[]=with_members`)
            .then((res) => { commit('setActiveShot', res.result); })
            .catch((e) => {
                if (e.response.status === 404) {
                    this.$router.push(`/${document.URL.split('/').slice(3,6).join('/')}`);
                } else {
                    const error = new Error(e.response.data.message);
                    error.statusCode = e.response.status;
                    throw error;
                }
            });
    },
    async fetchSequences({ commit }, projectID) {
        await this.$axios
            .$get(`${process.env.prodAPI}/sequences?filters[]=project:eq:${projectID}`)
            .then((res) => {
                commit('setSequences', res.result);
            })
            .catch((e) => {
                const error = new Error(e.response.data.message);
                error.statusCode = e.response.status;
                throw error;
            });
    },
    async fetchPasses({ commit }, { shotID, type }) {
        await this.$axios
            .$get(
                `${process.env.prodAPI}/passes?scopes[]=with_submits&scopes[]=created_meta&scopes[]=updated_meta&filters[]=shot:eq:${shotID}&filters[]=type:eq:${type}`
            )
            .then((res) => {
                commit('setActivePasses', {
                    type,
                    data: res.result,
                });
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    this.$router.push(`/${document.URL.split('/').slice(3,6).join('/')}`);
                } else {
                    const error = new Error(e.response.data.message);
                    error.statusCode = e.response.status;
                    throw error;
                }
            });
    },
};
