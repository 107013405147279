<template>
    <v-dialog
        v-model="open"
        width="376"
        persistent
    >
        <div class="dialog-body">
            <modal-header text="Add comment" :icon="false" @closeModal="closeModal" />
            <h3>Add comment for the changes</h3>
            <div>
                <textarea v-model="comment" style="border: 1px solid gainsboro; width: 100%; margin-top: 12px;height: 120px;" />
            </div>
            <v-autocomplete
                v-model="usersToNotify"
                :items="sortedUsers"
                :item-text="(item) => item.fullName + ' - ' + item.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                :menu-props="{ closeOnContentClick: true }"
                class="user-badges"
                label="Notify members"
                multiple
                solo
                dense
                chips
                return-object
                deletable-chips
            >
                <template #selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="
                                            usersToNotify = usersToNotify.filter(
                                                (user) => user.id !== data.item.id
                                            )
                                        "
                    >
                        <user-badge :user="data.item" is-small full-name />
                    </v-chip>
                </template>
                <template #item="{ item }">
                    <div :key="Math.random()" class="w-full">
                        <user-badge :user="item" full-name />

                    </div>
                </template>
            </v-autocomplete>

            <footer class="dialog-footer">
                <div class="col-span-2">
                    <v-btn color="primary" block @click="onSave">Save</v-btn>
                </div>
            </footer>

        </div>
    </v-dialog>
</template>

<script>
    // load user last 5 submit and generate checkbox
    import { mapGetters } from 'vuex';

    export default {
        props: {
            open: { type: Boolean, required: true },
            task: { type: Number, required: false, default: 0 },
            status: { type: Number, required: false, default: 0 },
            taskObject: { type: Object, required: false, default: null },
        },
        data() {
            return {
                submits: [],
                comment: '',
                sortedUsers: [],
                usersToNotify: [],
            };
        },
        beforeMount() {
            this.sortedUsers = this.users;
        },
        mounted() {
           this.comment = '';
        },
        updated() {
            if (!this.open) {
                this.comment = '';
            }
        },
        computed: {
            ...mapGetters({
                users: 'config/getUsers',
            }),
        },
        methods: {
            onSave() {
                const postData = new FormData();
                this.usersToNotify.forEach((user) => {
                    postData.append('usersToNotify[]', user.id);
                });
                postData.append('comment', this.comment);

                this.$axios.post(`${process.env.prodAPI}/taskcomment/${this.task}`, postData);
                this.closeModal();
            },
            closeModal() {
                this.usersToNotify = [];
                this.$emit('updateModal', false);
                let returnData = this.taskObject;
                if (returnData === null) {
                    returnData = this.task;
                }
                this.$emit('callback', returnData, this.status);
            },

        }
    }
</script>