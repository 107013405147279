<template>
    <div class="relative w-fit" :class="{'w-16': !fullImage}" >
        <input
            :id="`thumbnail_upload-${_uid}`"
            ref="fileInput"
            type="file"
            :name="`thumbnail_upload-${_uid}`"
            accept="image/png, image/jpeg, image/jpg"
            :disabled="disabled"
            class="hidden"
            @change="onChange"
        />

        <label
            :for="`thumbnail_upload-${_uid}`"
            class="block h-16 w-fit cursor-pointer relative rounded-md overflow-hidden"
            :class="{'w-16': !fullImage, 'pointer-events-none': disabled, 'bg-gray-300': !noBackground }"
        >
            <i
                v-if="!fullImage"
                class="las la-upload upload-icon text-lg text-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity"
            ></i>
            <span
                class="thumbnail relative inline-block h-16 bg-cover bg-no-repeat bg-center overflow-hidden"
                :class="{'w-16': !fullImage}"
                :style="fullImage || !imageSrc ? '' : `background-image: url(${imageSrc})`"
            >
                <i class="las la-camera text-2xl text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                <img v-if="fullImage" :src="imageSrc" alt="" class="h-16 min-w-16" :class="{'w-16': isThumbnailCleared}" />
            </span>
        </label>
        <button
            v-if="(thumbnail || previewImage) && !isThumbnailCleared && !disabled && !noClear"
            type="button"
            class="delete-thumbnail-button"
            @click="clear"
        >
            <i class="las la-trash text-grey-800 transition"></i>
        </button>
        <button
            v-if="previewImage && !disabled"
            type="button"
            class="reset-thumbnail-button"
            @click="reset"
        >
            <i class="las la-sync text-grey-800 transition"></i>
        </button>
    </div>
</template>

<script>

export default {
    name: 'ThumbnailUpload',
    props: {
        thumbnail: { type: String, required: false, default: null },
        disabled: { type: Boolean, required: false, default: false },
        fullImage: { type: Boolean, required: false, default: false },
        noClear: { type: Boolean, required: false, default: false },
        noBackground: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            previewImage: null,
            isThumbnailCleared: false,
            url: null,
        };
    },
    computed: {
        thumbnailSrc() {
            return this.url && !this.isThumbnailCleared ? this.url : null;
        },
        imageSrc() {
            return this.previewImage ? this.previewImage : this.thumbnailSrc ? this.thumbnailSrc : '';
        }
    },
    mounted() {
        this.url = `${process.env.backendURL}/${this.thumbnail}`;
        this.$nuxt.$on('clearNewProjectThumbnailFile', this.reset);
    },
    destroyed() {
        this.$nuxt.$off('clearNewProjectThumbnailFile', this.reset);
    },
    methods: {
        onChange(e) {
            this.isThumbnailCleared = false;
            this.previewImage = URL.createObjectURL(e.target.files[0]);
            this.$emit('change', e.target.files[0]);
        },
        clear() {
            this.isThumbnailCleared = true;
            this.previewImage = null;
            this.$emit('change', false);
            this.$refs.fileInput.value = '';
        },
        reset() {
            this.isThumbnailCleared = false;
            this.previewImage = null;
            this.$emit('change', null);
            this.$refs.fileInput.value = '';
        },
        // Used externally (GeneralSettings.vue)
        setUploaded() {
            if (this.previewImage) {
                this.url = this.previewImage;
                this.previewImage = null;
                this.isThumbnailCleared = false;
            }
        }
    },
};
</script>

<style scoped>
.thumbnail i {
    opacity: 0;
    transition: opacity 0.3s;
}
.thumbnail:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 0.3s;
}
.delete-thumbnail-button {
    @apply h-8 w-8 rounded-full bg-dark-800 text-white z-2 absolute -right-4 -bottom-2 flex items-center justify-center text-lg transition;
    &:hover {
        @apply bg-red-400;
        i {
            @apply text-white;
        }
    }
}
.reset-thumbnail-button {
    @apply h-8 w-8 rounded-full bg-dark-800 text-white z-2 absolute -left-4 -bottom-2 flex items-center justify-center text-lg transition;
    &:hover {
        @apply bg-white;
        i {
            @apply text-success;
        }
    }
}
label:hover {
    .upload-icon {
        opacity: 0;
    }
    .thumbnail {
        i,
        &:before {
            opacity: 1;
        }
    }
}
</style>
