<template>
    <v-dialog
        v-model="dialog"
        :overlay-opacity="$store.state.vuetify.dialog.overlayOpacity"
        :overlay-color="$store.state.vuetify.dialog.overlayColor"
        width="800"
    >
        <template v-if="description" #activator="{ on }">
            <a v-if="!custom" class="flex items-center cursor-pointer" v-on="on">
                <i class="las la-search-plus text-primary text-xl mr-1" />
                <span
                    v-if="$store.state.users.me.screenSize >= 2"
                    class="description_preview"
                    :style="!!previewStyle ? previewStyle : ''"
                >
                    {{ format(description) }}
                </span>
            </a>
            <a v-else v-on="on"><slot /></a>
        </template>
        <template v-else #activator>
            <span>-</span>
        </template>

        <div class="dialog-body">
            <modal-header no-tab text="" @closeModal="closeModal">
                <div v-if="title" class="mr-2 text-lg whitespace-nowrap">{{ title }}</div>
            </modal-header>
            <div class="description_content" v-html="description"></div>
            <footer class="dialog-footer">
                <div class="col-span-4"></div>
                <div>
                    <v-btn block @click="closeModal">Close</v-btn>
                </div>
            </footer>
        </div>
    </v-dialog>
</template>

<script>
import linkifyHtml from 'linkifyjs/html';

export default {
    props: {
        description: { type: String, required: false, default: '' },
        title: { type: String, required: false, default: '' },
        custom: { type: Boolean, required: false, default: false },
        previewStyle: {type: String, required: false, default: '' },
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        closeModal() {
            setTimeout(() => { this.dialog = false; }, 50);
        },
        format(desc) {
            if (!desc) return '';
            return linkifyHtml(desc.replaceAll(/(<([^>]+)>)/ig, ' ').replaceAll(/ +/ig, ' '), {
                target: '_blank',
            });
        }
    },
};
</script>

<style lang="postcss" scoped>
.description_content {
    word-break: break-word;
    word-wrap: break-word;
}
.description_preview {
    display: inline-block;
    max-width: max(9rem, 10vw);
    overflow: clip;
    text-overflow: ellipsis;
}
</style>
