<template>
    <div class="-mb-8 mt-6">
        <span class="shadow-line shadow-line-before" />
        <nav
            class="sidebar-menu transition-all overflow-y-scroll overflow-x-hidden pb-2 pt-4 scrollbar-thin scrollbar-thumb-gray-500 dark:scrollbar-thumb-dark-800 scrollbar-track-gray-100 dark:scrollbar-track-dark-700"
            :class="{ 'admin-open': adminMenuOpen }"
        >
            <transition-group tag="ul" name="filter_anim" class="filter_anim block">
                <li
                    v-for="subscription in activeProjectSubscriptions"
                    :key="'p_' + subscription.id"
                    class="filter_anim-item last-of-type:pb-4"
                >
                    <button class="w-max" @contextmenu="showContextMenu($event, subscription)">
                        <div class="group flex items-center transition-colors hover:bg-gray-300 dark-hover:bg-dark-800">
                            <nuxt-link
                                :to="projectLink(subscription.project.id, subscription.project.status)"
                                class="sidebar-project-link py-3 px-4 w-100 transition-colors duration-300 flex items-center relative"
                            >
                                <div
                                    class="color-badge w-1 h-full absolute top-0 left-0 transition-opacity bg-system-purple/50"
                                    :class="$getProjectStatusColor(subscription.project.status).bg"
                                />
                                <v-tooltip right>
                                    <template #activator="{ on }">
                                        <div v-on="sidebarOpen ? null : on">
                                            <project-badge-inside
                                                :project="subscription.project"
                                                :additional-classes="`${!sidebarOpen ? 'ml-0.5' : 'pointer-events-none'} `"
                                                icon-only
                                                has-large-icon
                                            >
                                                <template #insideBadge>
                                                    <span
                                                        v-if="subscription.project.hasMilestoneToday"
                                                        class="dot -top-1.5 -left-1.5 bg-red"
                                                    />
                                                    <span
                                                        v-else-if="subscription.project.hasMilestoneTomorrow"
                                                        class="dot -top-1.5 -left-1.5 bg-yellow"
                                                    />
                                                </template>
                                            </project-badge-inside>
                                        </div>
                                    </template>
                                    <span v-if="!sidebarOpen" class="text-xs">{{ subscription.project.name }}</span>
                                </v-tooltip>
                                <transition name="fade">
                                    <span
                                        v-if="sidebarOpen"
                                        class="relative text-xs text-secondary dark:text-white font-gotham-400 max-w-[90px] overflow-hidden overflow-ellipsis"
                                    >
                                        {{ subscription.project.name }}
                                    </span>
                                </transition>
                            </nuxt-link>
                            <v-menu
                                v-if="
                                    $checkPermission('project.update') ||
                                    $checkPermission('message.create') ||
                                    $checkPermission('task.create')
                                "
                                v-model="showThreeButtonMenu[subscription.project.name]"
                                :close-on-content-click="true"
                                offset-y
                                transition="slide-x-transition"
                                z-index="30"
                            >
                                <template #activator="{ on }">
                                    <v-btn class="ml-auto mr-4 text-2xl" icon x-small v-on="on" @click="showThreeButtonMenu[subscription.project.name] = true">
                                        <i
                                            class="las la-ellipsis-v transition-colors text-gray-600 group-hover:text-secondary dark-group-hover:text-white"
                                            :class="{
                                                'text-secondary dark:text-white': subscription.project.id === activePicker,
                                            }"
                                        />
                                    </v-btn>
                                </template>
                                <div class="v-menu-body">
                                    <ActionContextMenu
                                        :custom="[
                                            {
                                                permission: $checkPermission('project.update'),
                                                label: 'Edit',
                                                url: `/projects/${subscription.project.id}/details`,
                                                icon: 'la-cog',
                                            },
                                            {
                                                type: 'project-folder',
                                                label: 'Open folder',
                                                url: $folderPath(subscription.project.relPath),
                                                icon: 'la-folder-open',
                                            },
                                        ]"
                                        :task="{
                                            entityId: subscription.project.id,
                                            entityTypeId: 2,
                                        }"
                                        :message="{
                                            entityId: subscription.project.id,
                                            entityTypeId: 2,
                                        }"
                                    />
                                </div>
                            </v-menu>
                        </div>
                    </button>
                </li>
            </transition-group>
            <v-menu
                v-if="
                    $checkPermission('project.update') ||
                    $checkPermission('message.create') ||
                    $checkPermission('task.create')
                "
                v-model="contextMenu.show"
                :position-x="contextMenu.x"
                :position-y="contextMenu.y"
                absolute
                offset-y
                :close-on-content-click="true"
            >
                <div class="v-menu-body">
                    <ActionContextMenu
                        v-if="contextMenu.subscription"
                        :custom="[
                            {
                                permission: $checkPermission('project.update'),
                                label: 'Edit',
                                url: `/projects/${contextMenu.subscription.project.id}/details`,
                                icon: 'la-cog',
                            },
                            {
                                type: 'project-folder',
                                label: 'Open folder',
                                url: $folderPath(contextMenu.subscription.project.relPath),
                                icon: 'la-folder-open',
                            },
                        ]"
                        :task="{
                            entityId: contextMenu.subscription.project.id,
                            entityTypeId: 2,
                        }"
                        :message="{
                            entityId: contextMenu.subscription.project.id,
                            entityTypeId: 2,
                        }"
                    />
                </div>
            </v-menu>
        </nav>
        <span class="shadow-line shadow-line-after" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProjectBadgeInside from '@/components/UI/Projects/ProjectBadgeInside';

export default {
    components: {
        ProjectBadgeInside,
    },
    data() {
        return {
            projects: [],
            activePicker: false,
            contextMenu: {
                subscription: null,
                show: false,
                x: 0,
                y: 0,
            },
            showThreeButtonMenu: {},
        };
    },
    computed: {
        ...mapGetters({
            sidebarOpen: 'preferences/getSidebarState',
            adminMenuOpen: 'getSidebarAdminState',
            activeProjectSubscriptions: 'users/me/getActiveProjectSubscriptions',
        }),
    },
    methods: {
        projectLink(id, status) {
            // todo: ezt berakni redirect middleware-be ha lehet
            let link = '/projects/' + id;
            if (['preproduction', 'on_hold_pre'].includes(status)) {
                const lastVisitedPreproductionProjectTab =
                    this.$store.state.routing.savedPreproductionProjectTabRoutes[id];
                if (lastVisitedPreproductionProjectTab) {
                    // link += '/preproduction' + lastVisitedPreproductionProjectTab;
                }
            } else {
                const lastVisitedProjectTab = this.$store.state.routing.savedProjectTabRoutes[id];
                const lastVisitedDetailTab = this.$store.state.routing.savedProjectDetailRoutes[id];
                const lastVisitedShotTab = this.$store.state.routing.savedProjectShotRoutes[id];
                if (lastVisitedProjectTab) {
                    link += lastVisitedProjectTab;
                }
                if (lastVisitedProjectTab === '/details' && lastVisitedDetailTab) {
                    link += lastVisitedDetailTab;
                } else if (lastVisitedProjectTab === '/work/shots' && lastVisitedShotTab) {
                    link += lastVisitedShotTab;
                }
            }

            return link;
        },
        showContextMenu(e, subscription) {
            e.preventDefault();
            this.contextMenu.show = false;
            Object.keys(this.showThreeButtonMenu).forEach((item) => {this.showThreeButtonMenu[item] = null; });
            this.contextMenu.subscription = subscription;
            this.contextMenu.x = e.clientX - 24;
            this.contextMenu.y = e.clientY;
            this.$nextTick(() => {
                this.contextMenu.show = true;
            });
        },
    },
};
</script>

<style scoped lang="postcss">
.dot {
    @apply absolute block h-4 w-4 rounded-full border-3 border-white dark:border-dark-850 group-hover:border-gray-300 dark-group-hover:border-dark-800 transition-colors;
}

.nuxt-link-active {
    &:before {
        @apply absolute top-0 left-0 h-full bg-gray-300 dark:bg-dark-800 z-0;
        content: '';
        width: 500%;
    }
}

.filter_anim {
    max-height: calc(100vh - 275px);
    @apply pb-6;

    &-enter,
    &-leave-to {
        @apply opacity-0 max-h-0;
    }

    &-enter-active,
    &-leave-active {
        @apply transition-all;
    }

    &-enter-to,
    &-leave {
        @apply opacity-100 max-h-20;
    }
}

.nuxt-link-active span {
    @apply font-gotham-700;
}
</style>
